import { executeAxios } from '../actions';

async function fetchData(filename) {
	try {
		const { data: file } = await executeAxios({
			url: `/data/${filename}`,
			headers: {
				'Content-Type': 'application/json',
			},
			responseType: 'blob',
		});
		return file;
	} catch (error) {
		console.log(error);
	}
}

function getFile(file, mime, description) {
	const blob = new Blob([file], { type: mime });
	const link = document.createElement('a');
	link.href = window.URL.createObjectURL(blob);
	link.download = description;
	link.click();
}

export async function downloadFile(filename, mime, description) {
	const file = await fetchData(filename);
	getFile(file, mime, description);
}
