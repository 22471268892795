import { useState } from 'react';
import Select from 'react-select';
import {
	faAlignCenter,
	faAlignJustify,
	faAlignLeft,
	faAlignRight,
	faBold,
	faItalic,
	faLink,
	faLinkSlash,
	faListOl,
	faListUl,
	faStrikethrough,
	faSubscript,
	faSuperscript,
	faUnderline,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { InputCheckbox, InputField } from 'components/Form';
import Modal from 'components/Modal/Modal';

const Inline = ({ currentState, onChange }) => {
	const { bold, italic, underline, strikethrough, superscript, subscript } =
		currentState;

	return (
		<>
			<div className='inline-element'>
				<button
					className={bold ? 'active' : ''}
					onClick={() => onChange('bold', !bold)}
					aria-label='bold'>
					<FontAwesomeIcon icon={faBold} />
				</button>
				<button
					className={italic ? 'active' : ''}
					onClick={() => onChange('italic', !italic)}
					aria-label='italic'>
					<FontAwesomeIcon icon={faItalic} />
				</button>
				<button
					className={underline ? 'active' : ''}
					onClick={() => onChange('underline', !underline)}
					aria-label='underline'>
					<FontAwesomeIcon icon={faUnderline} />
				</button>
			</div>
			<div className='inline-element'>
				<button
					className={strikethrough ? 'active' : ''}
					onClick={() => onChange('strikethrough', !strikethrough)}
					aria-label='strikethrough'>
					<FontAwesomeIcon icon={faStrikethrough} />
				</button>
				<button
					className={superscript ? 'active' : ''}
					onClick={() => onChange('superscript', !superscript)}
					aria-label='superscript'>
					<FontAwesomeIcon icon={faSuperscript} />
				</button>
				<button
					className={subscript ? 'active' : ''}
					onClick={() => onChange('subscript', !subscript)}
					aria-label='subscript'>
					<FontAwesomeIcon icon={faSubscript} />
				</button>
			</div>
		</>
	);
};

const List = ({ onChange }) => {
	return (
		<div className='inline-element'>
			<button
				onClick={() => onChange('ordered')}
				aria-label='ordered list'>
				<FontAwesomeIcon icon={faListOl} />
			</button>
			<button
				onClick={() => onChange('unordered')}
				aria-label='unordered list'>
				<FontAwesomeIcon icon={faListUl} />
			</button>
		</div>
	);
};

// eslint-disable-next-line no-unused-vars
const Link = ({
	currentState,
	onChange,
	expanded,
	onExpandEvent,
	doCollapse,
}) => {
	const { selectionText, link } = currentState;
	const [url, setUrl] = useState(link);
	const [target, setTarget] = useState(true);

	const stopPropagation = event => {
		event.stopPropagation();
	};

	const onClick = () => {
		onChange('link', selectionText, url, target ? '_blank' : '_self');
		doCollapse();
	};

	const renderModal = () => {
		return (
			<Modal
				show={expanded || false}
				close={doCollapse}
				onClick={stopPropagation}
				actions={[
					{
						action: onClick,
						label: 'Toevoegen',
					},
				]}>
				<InputField
					name='url'
					label='Link'
					value={url}
					onChange={setUrl}
					validate={() => ''}
					required
				/>

				<InputCheckbox
					name='target'
					label='Open in een nieuw venster'
					value={target}
					onChange={() => setTarget(!target)}
					validate={() => ''}
				/>
			</Modal>
		);
	};

	return (
		<div className='inline-element'>
			<button
				onClick={onExpandEvent}
				disabled={!selectionText}
				aria-label='link'>
				<FontAwesomeIcon icon={faLink} />
			</button>
			<button
				onClick={() => onChange('unlink')}
				disabled={!link}
				aria-label='unlink'>
				<FontAwesomeIcon icon={faLinkSlash} />
			</button>

			{renderModal()}
		</div>
	);
};

const TextAlign = ({ currentState, onChange }) => {
	const { textAlignment } = currentState;

	return (
		<div className='inline-element'>
			<button
				onClick={() => onChange('left')}
				className={textAlignment === 'left' ? 'active' : ''}
				aria-label='align left'>
				<FontAwesomeIcon icon={faAlignLeft} />
			</button>
			<button
				onClick={() => onChange('center')}
				className={textAlignment === 'center' ? 'active' : ''}
				aria-label='align center'>
				<FontAwesomeIcon icon={faAlignCenter} />
			</button>
			<button
				onClick={() => onChange('right')}
				className={textAlignment === 'right' ? 'active' : ''}
				aria-label='align right'>
				<FontAwesomeIcon icon={faAlignRight} />
			</button>
			<button
				onClick={() => onChange('justify')}
				className={textAlignment === 'justify' ? 'active' : ''}
				aria-label='align justify'>
				<FontAwesomeIcon icon={faAlignJustify} />
			</button>
		</div>
	);
};

// eslint-disable-next-line no-unused-vars
const BlockType = ({ currentState, onChange }) => {
	const options = [
		{ value: 'Normal', label: 'Normaal' },
		{ value: 'H2', label: 'h2' },
		{ value: 'H3', label: 'h3' },
	];
	const { blockType } = currentState;

	const [value, setValue] = useState(
		options.find(x => x.value === blockType),
	);

	const onClick = selectInput => {
		setValue(options.find(x => x.value === selectInput.label));
		onChange(selectInput.value);
	};

	return (
		<div className='inline-element'>
			<Select
				value={value}
				options={options}
				onChange={onClick}
				className='select'
				classNamePrefix='select'
				styles={{
					menuPortal: base => ({
						...base,
						zIndex: 9999999,
						marginLeft: '-0.5rem',
					}),
				}}
				menuPortalTarget={document.getElementById('ketchup-base')}
				menuPosition='absolute'
			/>
		</div>
	);
};

export const toolbar = {
	options: [
		'inline',
		'list',
		'link',
		'textAlign',
		'blockType',
		//'emoji',
	],
	inline: {
		component: Inline,
	},
	list: {
		component: List,
	},
	link: {
		component: Link,
	},
	textAlign: {
		component: TextAlign,
	},
	blockType: {
		component: BlockType,
	},
	emoji: {
		className: undefined,
		component: undefined,
		popupClassName: undefined,
		emojis: [
			'😀',
			'😁',
			'😂',
			'😃',
			'😉',
			'😋',
			'😎',
			'😍',
			'😗',
			'🤗',
			'🤔',
			'😣',
			'😫',
			'😴',
			'😌',
			'🤓',
			'😛',
			'😜',
			'😠',
			'😇',
			'😷',
			'😈',
			'👻',
			'😺',
			'😸',
			'😹',
			'😻',
			'😼',
			'😽',
			'🙀',
			'🙈',
			'🙉',
			'🙊',
			'👼',
			'👮',
			'🕵',
			'💂',
			'👳',
			'🎅',
			'👸',
			'👰',
			'👲',
			'🙍',
			'🙇',
			'🚶',
			'🏃',
			'💃',
			'⛷',
			'🏂',
			'🏌',
			'🏄',
			'🚣',
			'🏊',
			'⛹',
			'🏋',
			'🚴',
			'👫',
			'💪',
			'👈',
			'👉',
			'👉',
			'👆',
			'🖕',
			'👇',
			'🖖',
			'🤘',
			'🖐',
			'👌',
			'👍',
			'👎',
			'✊',
			'👊',
			'👏',
			'🙌',
			'🙏',
			'🐵',
			'🐶',
			'🐇',
			'🐥',
			'🐸',
			'🐌',
			'🐛',
			'🐜',
			'🐝',
			'🍉',
			'🍄',
			'🍔',
			'🍤',
			'🍨',
			'🍪',
			'🎂',
			'🍰',
			'🍾',
			'🍷',
			'🍸',
			'🍺',
			'🌍',
			'🚑',
			'⏰',
			'🌙',
			'🌝',
			'🌞',
			'⭐',
			'🌟',
			'🌠',
			'🌨',
			'🌩',
			'⛄',
			'🔥',
			'🎄',
			'🎈',
			'🎉',
			'🎊',
			'🎁',
			'🎗',
			'🏀',
			'🏈',
			'🎲',
			'🔇',
			'🔈',
			'📣',
			'🔔',
			'🎵',
			'🎷',
			'💰',
			'🖊',
			'📅',
			'✅',
			'❎',
			'💯',
		],
	},
};
