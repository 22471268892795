import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import {
	changeMode,
	changeTheme,
} from 'core/store/application/application.actions';
import { node } from 'prop-types';

export const ThemeContext = createContext({
	mode: 'auto',
	theme: window.matchMedia('(prefers-color-scheme: dark)') ? 'dark' : 'light',
});

export const ThemeProvider = ({ children }) => {
	const { theme, mode } = useSelector(state => state.applicationReducer);

	const updateTheme = () => {
		let newMode;
		switch (mode) {
			case 'light':
				newMode = 'dark';
				break;
			case 'auto':
				newMode = 'light';
				break;
			case 'dark':
			default:
				newMode = 'auto';
				break;
		}

		changeMode(newMode);
	};

	const mediaQueryListener = useCallback(
		e => {
			if (mode === 'auto') changeTheme(e.matches ? 'dark' : 'light');
			else changeTheme(mode);
		},
		[mode],
	);

	useEffect(() => {
		const darkThemeMediaQuery = window.matchMedia(
			'(prefers-color-scheme: dark)',
		);
		darkThemeMediaQuery.addEventListener('change', mediaQueryListener);
	}, [mode, mediaQueryListener]);

	return (
		<ThemeContext.Provider
			value={{ theme, mode, toggleTheme: updateTheme }}>
			<div className={`ketchup-base theme--${theme}`} id='ketchup-base'>
				{children}
			</div>
		</ThemeContext.Provider>
	);
};

ThemeProvider.propTypes = {
	children: node,
};

export const useTheme = () => {
	return useContext(ThemeContext);
};
