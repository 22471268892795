export const arraySum = (array, key) => {
	let amount = 0;

	array.forEach(item => {
		if (typeof item === 'number') {
			amount += item || 0;
		} else if (key) {
			amount += Number(item[key] || 0);
		}
	});

	return amount;
};
