import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntityTypeDetails } from 'core/helpers/entities/entity.helper';
import { loadStaticDetails } from 'core/store/statics/statics.actions';

import Card from 'components/Card/Card';
import DataTable from 'components/DataTable/DataTable';
import { Button } from 'components/Form';
import { Col } from 'components/Grid';
import Modal from 'components/Modal/Modal';

const Visitors = ({ visitors, type, icon, isUnique }) => {
	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState(false);

	const statics = useSelector(state => state.staticsReducer);

	useEffect(() => {
		dispatch(loadStaticDetails(type, isUnique));
	}, [dispatch]);

	return (
		<Col md={6}>
			<Card
				title={
					<>
						<FontAwesomeIcon icon={icon} fixedWidth /> aantal
						{isUnique ? ' unieke ' : ' '}
						bezoekers
					</>
				}
				footer={
					<Button
						buttonStyle='link'
						label='Bekijk alles'
						ariaLabel={`bekijk alle ${isUnique ? ' unieke ' : ' '}bezoekers`}
						onClick={() => setShowModal(true)}
					/>
				}>
				<DataTable
					data={
						visitors?.length
							? visitors?.map((x, i) => ({
									...x,
									number: i + 1,
									name: (
										<Link to={`/${type}/${x.id}`}>
											{x.name ?? x.title}
										</Link>
									),
								}))
							: []
					}
					columns={[
						{ name: 'number', label: '#' },
						{ name: 'name', label: 'Naam' },
						{
							name: 'count',
							label: 'Bezoekers',
							className: 'right',
						},
					]}
				/>
			</Card>

			<Modal
				show={showModal}
				title={
					<>
						<FontAwesomeIcon icon={icon} fixedWidth /> aantal
						{isUnique ? ' unieke ' : ' '}
						bezoekers
					</>
				}
				close={() => setShowModal(!showModal)}
				large>
				<DataTable
					data={
						statics[getEntityTypeDetails(type).type] &&
						statics[getEntityTypeDetails(type).type][
							isUnique ? 'unique' : 'all'
						]?.length
							? statics[getEntityTypeDetails(type).type][
									isUnique ? 'unique' : 'all'
								]?.map((x, i) => ({
									...x,
									number: i + 1,
									name: x.name ?? x.title,
								}))
							: []
					}
					columns={[
						{ name: 'number', label: '#' },
						{ name: 'name', label: 'Naam' },
						{
							name: 'count',
							label: 'Bezoekers',
							className: 'right',
						},
					]}
					paginate
					search
				/>
			</Modal>
		</Col>
	);
};

export default Visitors;
