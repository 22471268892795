import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	any,
	array,
	bool,
	func,
	number,
	object,
	oneOfType,
	string,
} from 'prop-types';

import { Button, UiInput } from 'components/Form';

const InputField = ({
	type,
	id,
	name,
	label,
	placeholder,
	icon,
	action,
	actionIcon,
	description,
	value,
	required,
	focused,
	setFocused,
	touched,
	setTouched,
	readOnly,
	disabled,
	autocomplete,
	errors,
	onChange,
	className,
	onKeyDown,
}) => {
	return type === 'hidden' ? (
		<input type={type} name={name} id={id} value={value ?? null} />
	) : (
		<UiInput
			id={id}
			label={label}
			description={description}
			required={required}
			focused={focused}
			disabled={disabled || readOnly}
			className={className}
			hasValue={value.length > 0}
			hasError={errors.length > 0 && touched ? true : false}
			errors={errors}>
			{icon ? (
				<div className='form-field__control__icon'>
					<FontAwesomeIcon icon={icon} size='lg' fixedWidth />
				</div>
			) : null}
			<input
				type={type}
				name={name}
				id={id}
				value={value ?? ''}
				placeholder={`${placeholder}${
					placeholder && !required && !label ? ' (opt.)' : ''
				}`}
				autoComplete={autocomplete ? autocomplete : 'off'}
				onChange={e => onChange(e.target.value)}
				onBlur={() => {
					if (setTouched) setTouched(true);
					if (setFocused) setFocused(false);
				}}
				onFocus={() => {
					if (setFocused) setFocused(true);
				}}
				readOnly={readOnly}
				disabled={disabled}
				onKeyDown={onKeyDown}
			/>
			{action && actionIcon ? (
				<Button
					buttonStyle='primary'
					type='button'
					label={
						<FontAwesomeIcon
							icon={actionIcon}
							size='lg'
							fixedWidth
						/>
					}
					onClick={action}
					className='form-field__control__action'
					disabled={disabled}
				/>
			) : null}
		</UiInput>
	);
};

InputField.defaultProps = {
	type: 'text',
	label: '',
	placeholder: '',
	description: '',
	required: false,
	focused: false,
	touched: false,
	readOnly: false,
	disabled: false,
	autocomplete: false,
	validations: [],
	errors: [],
	value: '',
};

InputField.propTypes = {
	id: string,
	type: string,
	name: string.isRequired,
	label: string,
	placeholder: string,
	icon: object,
	action: object,
	actionIcon: object,
	description: string,
	value: oneOfType([string, number, array]),
	className: string,
	required: bool,
	focused: bool,
	setFocused: func,
	touched: bool,
	setTouched: func,
	readOnly: bool,
	disabled: bool,
	autocomplete: any,
	validations: array,
	errors: array,
	onChange: func.isRequired,
};

export default InputField;
