import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

let defaultState = {
	toppings: [],
	toppingsLoading: false,
};

const loadToppings = (state, { toppingsLoading }) => ({
	...state,
	toppingsLoading,
});

const loadToppingsCommit = (state, payload) => ({
	...state,
	toppingsLoading: false,
	toppings: payload,
});

export const toppingReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.TOPPINGS_LOAD:
			return loadToppings(state, payload);
		case ACTION_TYPES.TOPPINGS_LOAD_COMMIT:
			return loadToppingsCommit(state, payload.data);
		default:
			return state;
	}
};
