import { array, bool, func, number, oneOfType, string } from 'prop-types';

import { UiInput } from 'components/Form';

import './Toggle.scss';

const Toggle = ({
	id,
	name,
	label,
	groupLabel,
	description,
	value,
	required,
	touched,
	setTouched,
	readOnly,
	disabled,
	errors,
	onChange,
	className,
	style,
}) => {
	return (
		<UiInput
			id={id}
			label={groupLabel}
			description={description}
			required={required}
			disabled={disabled || readOnly}
			className={className}
			hasValue={!!value}
			hasError={errors.length > 0 && touched ? true : false}
			errors={errors}
			style={style}
			toggle>
			<label htmlFor={id}>
				<input
					name={name}
					type='checkbox'
					id={id}
					checked={value}
					onChange={() => {
						if (onChange) onChange(!value);
						if (setTouched) setTouched(true);
					}}
					readOnly={readOnly}
					disabled={disabled}
				/>
				<span>{label}</span> {required ? <code>(verpl.)</code> : null}{' '}
			</label>
		</UiInput>
	);
};

Toggle.defaultProps = {
	description: '',
	required: false,
	touched: false,
	readOnly: false,
	disabled: false,
	autocomplete: false,
	validations: [],
	errors: [],
	value: false,
};

Toggle.propTypes = {
	id: string,
	name: string.isRequired,
	label: string,
	groupLabel: string,
	description: string,
	value: oneOfType([bool, string, number]),
	className: string,
	required: bool,
	touched: bool,
	setTouched: func,
	readOnly: bool,
	disabled: bool,
	validations: array,
	errors: array,
	onChange: func.isRequired,
};

export default Toggle;
