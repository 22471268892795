import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

let defaultState = {
	organisers: [],
	organisersLoading: false,
};

const loadOrganisers = (state, { organisersLoading }) => ({
	...state,
	organisersLoading,
});

const loadOrganisersCommit = (state, payload) => ({
	...state,
	organisersLoading: false,
	organisers: payload,
});

export const organiserReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.ORGANISERS_LOAD:
			return loadOrganisers(state, payload);
		case ACTION_TYPES.ORGANISERS_LOAD_COMMIT:
			return loadOrganisersCommit(state, payload.data);
		default:
			return state;
	}
};
