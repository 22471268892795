import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	faBookmark as faBookmarkRegular,
	faFolder as faFolderRegular,
} from '@fortawesome/free-regular-svg-icons';
import {
	faBookmark as faBookmarkSolid,
	faCircleInfo,
	faFolder as faFolderSolid,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntityTypeDetails } from 'core/helpers/entities/entity.helper';
import { entryFormData } from 'core/helpers/entities/entry.form-data';
import { useForm } from 'core/hooks/form/useForm';
import { useSubmit } from 'core/hooks/submit/useSubmit';
import { postEntity } from 'core/store/entities/entity.actions';
import { loadInstructors } from 'core/store/instructors/instructor.actions';
import { loadOrganisers } from 'core/store/organisers/organiser.actions';
import { loadTags } from 'core/store/tags/tag.actions';

import Card from 'components/Card/Card';
import {
	Button,
	Datepicker,
	DatepickerInput,
	InputCheckbox,
	InputField,
	InputRadioButton,
	Select,
	Textarea,
	TinyEditor,
	Upload,
} from 'components/Form';
import { Col, Row } from 'components/Grid';
import { AdminHeader } from 'components/Layouts/AdminLayout/AdminHeader/AdminHeader';
import Content from 'components/Layouts/Content/Content';
import Sidebar from 'components/Layouts/Sidebar/Sidebar';
import { UserSelector } from 'components/UserSelector/UserSelector';

import {
	createBuilderFromForm,
	createFormFromBuilder,
} from '../../../core/helpers/form-builder/form-builder.helper';
import FormBuilder from '../../FormBuilder/FormBuilder';

import AddDateInfo from './AddDateInfo';
import AddEntityLink from './AddEntityLink';

import './EntityForm.scss';

const EntityForm = ({ entity, object, ...props }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { handleSubmit } = useSubmit();

	const { role } = useSelector(state => state.authReducer);
	const { permissions } = useSelector(state => state.authReducer);
	const { organisers } = useSelector(state => state.organiserReducer);
	const { instructors } = useSelector(state => state.instructorReducer);
	const { tags } = useSelector(state => state.tagReducer);

	useEffect(() => {
		dispatch(loadOrganisers());
		dispatch(loadTags());
		dispatch(loadInstructors());
	}, [dispatch]);

	useEffect(() => {
		const temp = {};
		object?.days?.forEach(day => {
			const item = object.days.find(x => x.date === day.date);
			temp[day.date] = {
				time: {
					from: item.startTime,
					until: item.endTime,
				},
				bread: item.bread,
				reason: item.reason,
			};
		});

		setDateInfo(temp);
	}, [object]);

	const [form] = useState(entryFormData(entity, object));
	const [customForm, setCustomForm] = useState(
		createBuilderFromForm(props.customForm),
	);
	const [invitees, setInvitees] = useState(object?.invitees ?? []);
	const [dateInfo, setDateInfo] = useState({});
	const [showFormBuilder, setShowFormBuilder] = useState(false);

	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const { register, getValue, getValues, isValid, reset, setValue } =
		useForm();

	const submitForm = async () => {
		const values = {
			...getValues(),
			attachments: getValue('attachments').filter(x => !x.id),
			date_info: dateInfo,
			invitees: invitees.map(x => x.id),
			form:
				getValue('form') === 'none'
					? null
					: createFormFromBuilder(customForm),
		};

		await handleSubmit(postEntity(values, object?.id, entity, navigate));
	};

	return showFormBuilder ? (
		<DndProvider backend={HTML5Backend}>
			<FormBuilder
				form={customForm}
				save={setCustomForm}
				close={() => setShowFormBuilder(!showFormBuilder)}
			/>
		</DndProvider>
	) : (
		<Row className='entity'>
			<Col md={12}>
				<AdminHeader
					title={`${form.type.options.find(x => x.value === form.type.value).label} ${object ? 'wijzigen' : 'toevoegen'}`}
				/>

				<Row>
					<Content hasSidebar>
						<InputField {...register('title', form.title)} />

						<TinyEditor {...register('body', form.body)} />

						<Textarea {...register('short', form.short)} />

						{getValue('type') !== 'news' ? (
							<>
								<div className='period'>
									<Datepicker
										{...register('date', form.date)}
									/>

									<AddDateInfo
										id='add-date-info'
										dates={getValue('date')}
										dateInfo={dateInfo}
										setDateInfo={setDateInfo}
										type={getValue('form')}
									/>
								</div>

								<Row style={{ marginTop: 50 }}>
									<Col lg={12}>
										<h2>Genodigden</h2>
									</Col>

									<Col lg={6}>
										<InputField
											{...register(
												'max_attendees',
												form.max_attendees,
											)}
										/>
									</Col>
									<Col lg={6}>
										<DatepickerInput
											{...register(
												'max_attend_date',
												form.max_attend_date,
											)}
										/>
									</Col>

									<Col lg={12}>
										<UserSelector
											title='Medewerkers'
											selected={invitees}
											setSelected={setInvitees}
										/>
									</Col>
								</Row>
							</>
						) : null}

						<Row style={{ marginTop: 50 }}>
							<Col lg={12}>
								<h2>Bijlagen</h2>
							</Col>
							<Col lg={6}>
								<Upload
									{...register('attachments', {
										...form.attachments,
										description: (
											<span className='info_comment'>
												<span>
													Enkel bestanden kleiner dan
													10MB zijn toegelaten.
												</span>
												<FontAwesomeIcon
													icon={faCircleInfo}
													onClick={() =>
														setIsPopoverOpen(
															!isPopoverOpen,
														)
													}
												/>
											</span>
										),
									})}
								/>

								{isPopoverOpen ? (
									<ul className='attachment-info'>
										<li>
											<FontAwesomeIcon
												icon={faBookmarkRegular}
											/>{' '}
											Maak de afbeelding primair. Als er
											geen afbeeldingen primair zijn,
											wordt de eerste niet primaire
											afbeelding gebruikt.
										</li>
										<li>
											<FontAwesomeIcon
												icon={faBookmarkSolid}
											/>{' '}
											Primaire afbeelding. Deze wordt niet
											getoond in het overzicht op de
											detailpagina.
										</li>
										<li>
											<FontAwesomeIcon
												icon={faFolderRegular}
											/>{' '}
											Afbeelding: toegevoegd aan het
											overzicht.
										</li>
										<li>
											<FontAwesomeIcon
												icon={faFolderSolid}
											/>{' '}
											Afbeelding: toegevoegd als bijlage.
										</li>
									</ul>
								) : null}
							</Col>

							<Col md={12}>
								<div className='btn__group mt-5'>
									<Button
										buttonStyle='primary'
										label='Bewaar'
										disabled={
											!isValid() ||
											(getValue('form') === 'custom' &&
												customForm.length === 0)
										}
										onClick={submitForm}
									/>
									<Button
										buttonStyle='secondary'
										label='Annuleer'
										onClick={() => {
											if (object) {
												navigate(
													`/${getEntityTypeDetails(entity).name.toLowerCase()}`,
												);
											} else {
												reset();
												setInvitees([]);
												setDateInfo({});
											}
										}}
									/>
								</div>
							</Col>
						</Row>
					</Content>

					<Sidebar>
						<Card title='Algemeen' withShadow>
							<Select {...register('type', form.type)} />

							{getValue('type') === 'news' &&
							['admin', 'dev', 'editor'].includes(role) ? (
								<>
									<InputCheckbox
										{...register(
											'important',
											form.important,
										)}
									/>

									<InputCheckbox
										{...register('pinned', form.pinned)}
									/>
								</>
							) : null}

							<Select
								{...register('tags', {
									...form.tags,
									options: tags.map(x => ({
										label: x,
										value: x,
									})),
								})}
							/>

							<InputField {...register('links', form.links)} />
							<AddEntityLink
								label='Voeg link toe aan bijlagen'
								value={getValue('links')}
								setValue={value => setValue('links', value)}
							/>
						</Card>

						{getValue('type') !== 'news' ? (
							<Card title='Instellingen' withShadow>
								<Select
									{...register('organisers', {
										...form.organisers,
										options: organisers.map(x => ({
											label: `${x.firstName} ${x.lastName}`,
											value: x.id,
										})),
									})}
								/>
								<Select
									{...register('location', form.location)}
									returnOptionsObj
								/>
								<InputCheckbox
									{...register('absence', {
										...form.absence,
										onChange: () => '',
									})}
								/>
								{getValue('absence') ? (
									<InputField
										{...register('hours', {
											...form.hours,
											required: getValue('absence'),
										})}
									/>
								) : null}
							</Card>
						) : null}

						<Card title='Bijkomend' withShadow>
							{getValue('type') === 'trainings' ? (
								<InputCheckbox
									{...register(
										'is_added_to_cv',
										form.is_added_to_cv,
									)}
								/>
							) : null}
							{getValue('type') !== 'news' ? (
								<Select
									{...register('instructor', {
										...form.instructor,
										options: instructors.map(x => ({
											label: `${x.firstName} ${x.lastName}`,
											value: x.id,
										})),
									})}
								/>
							) : null}
							<InputRadioButton
								{...register('form', {
									...form.form,
									options: form.form.options.map(x => {
										switch (x.value) {
											case 'training':
												return {
													...x,
													hide: !(
														getValue('type') !==
															'news' &&
														getValue('type') !==
															'events'
													),
												};
											case 'custom':
												return {
													...x,
													hide: !(
														getValue('type') !==
														'trainings'
													),
												};
											default:
												return x;
										}
									}),
									value:
										//prettier-ignore
										(customForm?.fields?.length ?? false)
											? 'custom'
											: form.form.value,
									disabled: object?.invitees?.some(
										x => x.confirmed !== null,
									),
								})}
							/>
							{getValue('form') === 'custom' ? (
								<Button
									buttonStyle={`secondary${customForm.fields.length ? ' success' : ''}`}
									onClick={() => setShowFormBuilder(true)}
									disabled={object?.invitees?.some(
										x => x.confirmed !== null,
									)}
									block>
									Formulier aanpassen
								</Button>
							) : null}
						</Card>

						<Card title='Publiceren' withShadow>
							<InputCheckbox
								{...register('publish', {
									...form.publish,
									disabled:
										!isValid() ||
										!permissions.includes(
											`${entity}_publish`,
										),
								})}
							/>

							{getValue('publish') ? (
								<>
									<DatepickerInput
										{...register(
											'published_at',
											form.published_at,
										)}
									/>

									{getValue('published_at') ? (
										<InputField
											{...register(
												'publish_time',
												form.publish_time,
											)}
										/>
									) : null}

									{getValue('type') === 'news' &&
									!getValue('published_at') ? (
										<InputCheckbox
											{...register('urgent', form.urgent)}
										/>
									) : null}
								</>
							) : null}
						</Card>
					</Sidebar>
				</Row>
			</Col>
		</Row>
	);
};

export default EntityForm;
