import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

const darkThemeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

let defaultState = {
	mode: 'auto',
	theme: darkThemeMediaQuery.matches ? 'dark' : 'light',
	departments: [],
	coces: [],

	permissions: [],
	permissionsLoading: false,
	roles: [],
	rolesLoading: false,

	sports: [],
	sportsLoading: false,
};

const changeTheme = (state, { theme }) => ({
	...state,
	theme:
		theme === 'auto'
			? darkThemeMediaQuery.matches
				? 'dark'
				: 'light'
			: theme,
});

const changeMode = (state, { theme }) => ({ ...state, mode: theme });

const loadCoces = (state, payload) => ({
	...state,
	coces: payload,
});
const loadDepartments = (state, payload) => ({
	...state,
	departments: payload,
});

const loadRoles = (state, { rolesLoading }) => ({
	...state,
	rolesLoading,
});

const loadRolesCommit = (state, payload) => ({
	...state,
	rolesLoading: false,
	roles: payload,
});

const loadPermissions = (state, { permissionsLoading }) => ({
	...state,
	permissionsLoading,
});

const loadPermissionsCommit = (state, payload) => ({
	...state,
	permissionsLoading: false,
	permissions: payload,
});

const putPermissions = (state, { roles }) => ({
	...state,
	roles: roles,
});

const loadSports = (state, { sportsLoading }) => ({
	...state,
	sportsLoading,
});

const loadSportsCommit = (state, payload) => ({
	...state,
	sportsLoading: false,
	sports: payload,
});

export const applicationReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.THEME_UPDATE:
			return changeTheme(state, payload.data);
		case ACTION_TYPES.THEME_MODE_UPDATE:
			return changeMode(state, payload.data);
		case ACTION_TYPES.COCES_LOAD_COMMIT:
			return loadCoces(state, payload.data);
		case ACTION_TYPES.DEPARTMENTS_LOAD_COMMIT:
			return loadDepartments(state, payload.data);
		case ACTION_TYPES.ROLES_LOAD:
			return loadRoles(state, payload);
		case ACTION_TYPES.ROLES_LOAD_COMMIT:
			return loadRolesCommit(state, payload.data);
		case ACTION_TYPES.SPORTS_LOAD:
			return loadSports(state, payload);
		case ACTION_TYPES.SPORTS_LOAD_COMMIT:
			return loadSportsCommit(state, payload.data);
		case ACTION_TYPES.PERMISSIONS_LOAD:
			return loadPermissions(state, payload);
		case ACTION_TYPES.PERMISSIONS_LOAD_COMMIT:
			return loadPermissionsCommit(state, payload.data);
		case ACTION_TYPES.PERMISSIONS_PUT_COMMIT:
			return putPermissions(state, payload);
		default:
			return state;
	}
};
