import { node, string } from 'prop-types';

import { Col } from 'components/Grid';

const Sidebar = ({ children }) => {
	return (
		<Col lg={4} md={5} mdOrder={2} smOrder={1}>
			{children}
		</Col>
	);
};

Sidebar.propTypes = {
	cartTitle: string,
	children: node.isRequired,
};

export default Sidebar;
