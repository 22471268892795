import React from 'react';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Col, Container, Row } from 'components/Grid';

const Unauthorized = () => {
	return (
		<div
			className={`error-wrapper theme--${
				window.matchMedia('(prefers-color-scheme: dark)').matches
					? 'dark'
					: 'light'
			}`}>
			<Container>
				<Row>
					<Col lg={4} md={6} sm={6} xs={12}>
						<h4>Jij bent hier niet welkom!</h4>
						<h1>401</h1>
						<p>
							Je hebt onvoldoende rechten om deze pagina te
							bekijken. Klik op onderstaande knop om terug naar de
							homepagina te gaan.
						</p>
						<p>
							Zou je toch tot deze pagina toegang moeten hebben?
							Neem contact op met{' '}
							<a href='mailto: support@unipartners.be'>
								support@unipartners.be
							</a>
							.
						</p>

						<a
							href='https://ketchup.unipartners.be'
							className='btn btn--secondary btn--block'>
							<FontAwesomeIcon icon={faHome} />
							<span>Terug naar de homepage</span>
						</a>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Unauthorized;
