import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loadEntity, loadEntityForm } from 'core/store/entities/entity.actions';

import { EntityForm } from 'components/Entity';
import Loading from 'components/Loading/Loading';

const EntityDetail = prop => {
	const { id } = useParams();

	const dispatch = useDispatch();

	const { entitiesLoading, entity, form } = useSelector(
		state => state.entityReducer,
	);

	useEffect(() => {
		if (id) {
			dispatch(loadEntity(prop.entity, id));
			dispatch(loadEntityForm(prop.entity, id));
		}
	}, [dispatch]);

	return entitiesLoading ? (
		<Loading large centered />
	) : (
		<EntityForm entity={prop.entity} object={entity} customForm={form} />
	);
};

export default EntityDetail;
