import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

let defaultState = {
	tags: [],
	tagsLoading: false,
};

const loadTags = (state, { tagsLoading }) => ({
	...state,
	tagsLoading,
});

const loadTagsCommit = (state, payload) => ({
	...state,
	tagsLoading: false,
	tags: payload,
});

const addTag = (state, { tag: { label } }) => ({
	...state,
	tags: [...state.tags, label],
});

export const tagReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.TAGS_LOAD:
			return loadTags(state, payload);
		case ACTION_TYPES.TAGS_LOAD_COMMIT:
			return loadTagsCommit(state, payload.data);
		case ACTION_TYPES.TAGS_ADD:
			return addTag(state, payload);
		default:
			return state;
	}
};
