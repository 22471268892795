import Modal from 'components/Modal/Modal';

import './Confirmation.scss';

export const Confirmation = ({ show, close, title, action, children }) => {
	return (
		<Modal
			show={show}
			close={close}
			title={title}
			cancel={false}
			small={true}
			className='confirmation'
			actions={[
				{
					className: 'success btn-block',
					action: () => {
						action();
						close();
					},
					label: 'Ja',
				},
				{
					className: 'error btn-block',
					action: close,
					label: 'Nee',
				},
			]}>
			{children}
		</Modal>
	);
};
