import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { movingMissionColors } from 'core/helpers/moving-mission/moving-mission.helper';
import { manipulateDate, toDateString, toShortDate } from 'core/services/date';
import { loadRegistrations } from 'core/store/moving-mission/moving-mission.actions';

import Card from 'components/Card/Card';

import './CompanyActivity.scss';

const CompanyActivity = () => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const { movingMissionLoading, registrations } = useSelector(
		state => state.movingMissionReducer,
	);

	const reloadRegistrations = (endDate, startDate) => {
		dispatch(loadRegistrations(id, endDate, startDate));
	};

	const handleDateChange = add => {
		const endDate = manipulateDate(registrations.endDate, 18, add);
		const startDate = manipulateDate(registrations.startDate, 18, add);

		reloadRegistrations(toDateString(endDate), toDateString(startDate));
	};

	return movingMissionLoading || !registrations?.topThree ? (
		''
	) : (
		<Card title='Activiteiten' className='activities'>
			<div className='activities__date-selector'>
				<div className='arrow' onClick={() => handleDateChange(false)}>
					<FontAwesomeIcon icon={faArrowLeft} />
				</div>
				{toShortDate(registrations.startDate)} -{' '}
				{toShortDate(registrations.endDate)}
				<div className='arrow' onClick={() => handleDateChange(true)}>
					<FontAwesomeIcon icon={faArrowRight} />
				</div>
			</div>

			<div className='activities__legend'>
				{Object.values(registrations?.topThree)
					.flat()
					.reduce((unique, activity) => {
						return unique.some(
							item =>
								item.type.toLowerCase() ===
								activity.type.toLowerCase(),
						)
							? unique
							: [...unique, activity];
					}, [])
					.map((activity, index) => {
						const capitalizedType =
							activity.type.charAt(0).toUpperCase() +
							activity.type.slice(1);
						return (
							<div
								className='activities__legend-item'
								key={index}>
								<div
									className='activities__legend-item__color'
									style={{
										backgroundColor:
											movingMissionColors[
												activity.type.toLowerCase()
											],
									}}
								/>
								{capitalizedType}
							</div>
						);
					})}
			</div>

			<div className='activities__graph'>
				{Object.keys(registrations?.topThree).map(day => (
					<div className='activities__graph-bar' key={day}>
						{registrations?.topThree[day].map((activity, index) => (
							<div
								className={`activities__graph-bar--segment ${activity.type.toLowerCase()}`}
								style={{
									height: `${activity.percentage}%`,
									backgroundColor:
										movingMissionColors[
											activity.type.toLowerCase()
										],
								}}
								key={index}
							/>
						))}
						<div className='activities__graph-bar--day'>
							{new Date(parseInt(day)).getDate()}
						</div>
					</div>
				))}
			</div>
		</Card>
	);
};

export default CompanyActivity;
