import axios from 'axios';

import { getToken } from '../auth/getToken';

export const executeAxios = async (config = {}) => {
	return axios({
		...config,
		baseURL: process.env.REACT_APP_NODE_API_URL,
		headers: {
			Authorization: `Bearer ${await getToken()}`,
			'X-Up-App': process.env.REACT_APP_API_KEY,
			...config.headers,
		},
	});
};
