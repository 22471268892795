import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { instructorsFormData } from 'core/helpers/instructors/instructors.form-data';
import { useForm } from 'core/hooks/form/useForm';
import { useSubmit } from 'core/hooks/submit/useSubmit';
import {
	deleteInstructor,
	handleInstructor,
	handleResetInstructor,
	loadInstructors,
} from 'core/store/instructors/instructor.actions';

import Card from 'components/Card/Card';
import { Confirmation } from 'components/Confirmation/Confirmation';
import DataTable from 'components/DataTable/DataTable';
import { Button, InputCheckbox, InputField } from 'components/Form';
import { Row } from 'components/Grid';
import { AdminHeader } from 'components/Layouts/AdminLayout/AdminHeader/AdminHeader';
import Content from 'components/Layouts/Content/Content';
import Sidebar from 'components/Layouts/Sidebar/Sidebar';
import Loading from 'components/Loading/Loading';

const Instructors = () => {
	const dispatch = useDispatch();
	// eslint-disable-next-line no-unused-vars
	const { handleSubmit } = useSubmit();

	const [form] = useState(instructorsFormData());
	const [confirmation, setConfirmation] = useState(null);

	const { register, getValues, getValue, setValue, isValid, reset } =
		useForm();

	const { instructors, instructorsLoading } = useSelector(
		state => state.instructorReducer,
	);

	useEffect(() => {
		dispatch(loadInstructors());
	}, []);

	const setInstructor = (key, id) => {
		const instructor = instructors.find(x => x.id === id);
		setValue('id', instructor.id);
		setValue('first_name', instructor.firstName);
		setValue('last_name', instructor.lastName);
		setValue('email', instructor.email);
		setValue('access_coce', instructor.canAccessCoce);
	};

	const submitForm = async () => {
		const { id, ...values } = getValues();
		await handleSubmit(handleInstructor(id, values));
		reset();
	};

	const handleReset = async () => {
		const { id } = getValues();
		await handleSubmit(handleResetInstructor(id));
	};

	const handleDelete = () => {
		dispatch(deleteInstructor(confirmation));
		setConfirmation(null);
	};

	return (
		<section>
			<AdminHeader title='Lesgevers' />

			<Row>
				<Content hasSidebar>
					<Card
						title='Lesgever toevoegen'
						footer={
							<div className='btn__group'>
								<Button
									buttonStyle='primary'
									type='button'
									onClick={() => submitForm()}
									label='Bewaren'
									disabled={!isValid()}
								/>
								<Button
									buttonStyle='secondary'
									type='button'
									onClick={() => reset()}
									label='Annuleren'
								/>
								{getValue('id') ? (
									<Button
										buttonStyle='link'
										type='button'
										onClick={() => handleReset()}
										label='Herstel wachtwoord'
									/>
								) : null}
							</div>
						}>
						<InputField {...register('id', form.id)} />
						<InputField
							{...register('first_name', form.first_name)}
						/>
						<InputField
							{...register('last_name', form.last_name)}
						/>
						<InputField {...register('email', form.email)} />
						<InputCheckbox
							{...register('access_coce', form.access_coce)}
						/>
					</Card>
				</Content>
				<Sidebar>
					<Card title='Lesgevers' withShadow>
						{instructorsLoading ? (
							<Loading />
						) : (
							<DataTable
								data={instructors.map(x => ({
									...x,
									canAccessCoce: x.canAccessCoce ? (
										<FontAwesomeIcon
											icon={faCheck}
											fixedWidth
											className='green'
										/>
									) : (
										<FontAwesomeIcon
											icon={faTimes}
											fixedWidth
											className='red'
										/>
									),
								}))}
								columns={[
									{
										name: 'lastName',
										label: 'Naam',
										sort: true,
									},
									{
										name: 'firstName',
										label: 'Voornaam',
										sort: true,
									},
									{
										name: 'actions',
										label: '',
										sort: false,
										actions: {
											update: setInstructor,
											remove: (key, id) =>
												setConfirmation(id),
										},
										className: 'right',
									},
								]}
								search
								paginate
							/>
						)}
					</Card>
				</Sidebar>
			</Row>

			<Confirmation
				show={!!confirmation}
				close={() => setConfirmation(null)}
				action={() => handleDelete()}
				title='Weet je het zeker?'>
				Weet je zeker dat je dit item wil verwijderen?
			</Confirmation>
		</section>
	);
};

export default Instructors;
