import { any, array, bool, func, number, string } from 'prop-types';

import { UiInput } from 'components/Form';

const Textarea = ({
	id,
	name,
	label,
	placeholder,
	description,
	value,
	rows,
	required,
	focused,
	setFocused,
	touched,
	setTouched,
	readOnly,
	disabled,
	errors,
	onChange,
	className,
}) => {
	return (
		<UiInput
			id={id}
			label={label}
			description={description}
			required={required}
			focused={focused}
			disabled={disabled || readOnly}
			className={className}
			hasValue={value?.length > 0}
			hasError={errors.length > 0 && touched ? true : false}
			errors={errors}>
			<textarea
				name={name}
				id={id}
				value={value ?? null}
				placeholder={`${placeholder}${
					placeholder && !required && !label ? ' (opt.)' : ''
				}`}
				onChange={e => onChange(e.target.value)}
				onBlur={() => {
					if (setTouched) setTouched(true);
					if (setFocused) setFocused(false);
				}}
				onFocus={() => {
					if (setFocused) setFocused(true);
				}}
				readOnly={readOnly}
				disabled={disabled}
				rows={rows}
			/>
		</UiInput>
	);
};

Textarea.defaultProps = {
	label: '',
	placeholder: '',
	description: '',
	rows: 5,
	required: false,
	focused: false,
	touched: false,
	readOnly: false,
	disabled: false,
	validations: [],
	errors: [],
	value: '',
};

Textarea.propTypes = {
	type: string,
	name: string.isRequired,
	label: string,
	placeholder: string,
	description: string,
	row: number,
	value: string,
	className: string,
	required: bool,
	focused: bool,
	setFocused: func,
	touched: bool,
	setTouched: func,
	readOnly: bool,
	disabled: bool,
	autocomplete: any,
	validations: array,
	errors: array,
};

export default Textarea;
