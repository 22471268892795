import {
	faBookmark as faBookmarkRegular,
	faFolder as faFolderRegular,
} from '@fortawesome/free-regular-svg-icons';
import {
	faBookmark as faBookmarkSolid,
	faFolder as faFolderSolid,
	faPaperclip,
	faTimes,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { removeAttachment } from 'core/helpers';
import { updateAttachment } from 'core/helpers/attatchments';
import { getFileSize } from 'core/services/file';
import { isValidFile } from 'core/services/form';
import { FileTypeIconService } from 'core/services/icon';
import { array, bool, func, object, oneOfType, string } from 'prop-types';

import { UiInput } from 'components/Form';

import './Upload.scss';

const Upload = ({
	id,
	name,
	label,
	description,
	value,
	required,
	focused,
	setFocused,
	touched,
	setTouched,
	readOnly,
	disabled,
	errors,
	onChange,
	onError,
	isMulti,
	className,
	accept,
	showOptions,
}) => {
	const handleAddFile = e => {
		const { files } = e.target;
		const err = [];
		const arr = [];

		for (let i = 0; i < files.length; i++) {
			const item = files.item(i);
			const size = Math.round(item.size / 1024);

			if (size >= 10240 || !isValidFile(item)) {
				if (size >= 10240) {
					err.push(`${item.name} is te groot`);
				}

				if (!isValidFile(item)) {
					err.push(`${item.name} is niet toegelaten`);
				}
			} else {
				const newFile = new File([item], item.name, {
					type: item.type,
				});

				newFile.disabled = false;
				newFile.primary = item.primary ?? false;
				newFile.attachment = item.attachment ?? !isImage(item.type);
				arr.push(newFile);
			}
		}

		setTouched(true);
		onChange(isMulti ? [...value, ...arr] : [...arr]);
		onError(err);
	};

	const handleRemove = async index => {
		const files = value;
		const file = files[index];

		if (file.id) {
			await removeAttachment(file.id);
		}

		files.splice(index, 1);
		onChange(files);
	};

	const handleRemoveAll = async () => {
		for (const file of value) {
			if (file.id) {
				await removeAttachment(file.id);
			}
		}
		onChange([]);
	};

	const handleChange = async (index, type) => {
		const files = await Promise.all(
			value.map(async (x, i) => {
				let newFile = new File([x], x.name, {
					type: x.type,
				});

				if (x.id) {
					newFile = x;
				}

				newFile.disabled = x.disabled ?? false;
				newFile.primary = type === 'primary' ? i === index : x.primary;
				newFile.attachment =
					type === 'attachment'
						? i === index
							? !x.attachment
							: x.attachment
						: x.attachment;

				if (x.id) {
					newFile.id = x.id;
					newFile.filename = x.filename;

					await updateAttachment(x.id, newFile);
				}

				return newFile;
			}),
		);

		console.log(files);
		onChange(files);
	};

	const isImage = type => {
		return /image\/png|image\/jpeg|imagesvg\+xml|image\/gif|image\/svg\+xml/.test(
			type,
		);
	};

	return (
		<>
			<UiInput
				id={id}
				label={label}
				description={description}
				required={required}
				focused={focused}
				disabled={disabled || readOnly}
				className={className}
				hasValue={value.length > 0}
				hasError={errors.length > 0 && touched ? true : false}
				errors={errors}
				isFileUpload>
				<input
					type='file'
					id={id}
					name={name}
					accept={accept}
					multiple={isMulti}
					disabled={disabled}
					onChange={handleAddFile}
					onBlur={() => {
						setTouched(true);
						setFocused(false);
					}}
					onFocus={() => setFocused(true)}
				/>
				<span className='form-field__label'>
					<div className='form-field__icon'>
						<FontAwesomeIcon
							icon={faPaperclip}
							size='lg'
							fixedWidth
						/>
					</div>
					<span>Bestand{isMulti ? 'en' : ''} toevoegen</span>
				</span>
				<span className='form-field__sub-label'>
					Sleep {isMulti ? 'de bestanden' : 'het bestand'} naar hier
				</span>
			</UiInput>

			{value.length !== 0 ? (
				<div className='form-field__upload-file-section'>
					{value.map((file, index) => (
						<div className='form-field__upload-file' key={index}>
							<div className='form-field__upload-file-label'>
								<FontAwesomeIcon
									icon={FileTypeIconService(
										file.type || 'text/uri-list',
									)}
									fixedWidth
								/>
								<span>
									{file.name}
									{file.size
										? ` (${getFileSize(file.size)})`
										: ''}
								</span>
							</div>
							<div className='form-field__upload-file__buttons'>
								{showOptions && isImage(file.type) ? (
									<>
										<span
											className='form-field__upload-file__extra'
											onClick={() =>
												handleChange(index, 'primary')
											}>
											<FontAwesomeIcon
												icon={
													file.primary
														? faBookmarkSolid
														: faBookmarkRegular
												}
											/>
										</span>
										<span
											className='form-field__upload-file__extra'
											onClick={() =>
												handleChange(
													index,
													'attachment',
												)
											}>
											<FontAwesomeIcon
												icon={
													file.attachment
														? faFolderSolid
														: faFolderRegular
												}
											/>
										</span>
									</>
								) : null}
								<button
									className='form-field__upload-file-remove-button'
									onClick={() => handleRemove(index)}>
									<FontAwesomeIcon
										icon={faTimes}
										fixedWidth
									/>
								</button>
							</div>
						</div>
					))}
					<div className='form-field__upload-buttons'>
						<button
							className='form-field__upload-remove-all-button'
							onClick={handleRemoveAll}>
							<FontAwesomeIcon icon={faTrash} fixedWidth />
							<span>Verwijder alles</span>
						</button>
					</div>
				</div>
			) : null}
		</>
	);
};

Upload.defaultProps = {
	type: 'file',
	label: '',
	description: 'Enkel bestanden kleiner dan 10MB zijn toegelaten',
	required: false,
	focused: false,
	touched: false,
	disabled: false,
	validations: [],
	errors: [],
	value: [],
	isMulti: false,
	accept: 'image/*, application/pdf',
};

Upload.propTypes = {
	id: string,
	name: string.isRequired,
	label: string,
	description: oneOfType([string, object]),
	value: oneOfType([array, string]),
	className: string,
	required: bool,
	focused: bool,
	setFocused: func,
	touched: bool,
	setTouched: func,
	readOnly: bool,
	disabled: bool,
	errors: array,
	onChange: func.isRequired,
	onError: func.isRequired,
	isMulti: bool,
	accept: string,
	showOptions: bool,
};

export default Upload;
