import { array, object, oneOfType, string } from 'prop-types';

import { Col, Row } from 'components/Grid';

const FormatGroup = ({ label, name, direction, children }) => {
	return (
		<Row>
			{label ? (
				<Col lg={12}>
					<label htmlFor={name}>{label}</label>
				</Col>
			) : null}

			{direction === 'vertical' ? (
				children.map((child, i) => {
					const breakpoint =
						children.length > 3 ? 4 : 12 / children.length;

					return (
						<Col md={breakpoint} key={i}>
							{child}
						</Col>
					);
				})
			) : (
				<Col lg={12}>{children}</Col>
			)}
		</Row>
	);
};

FormatGroup.defaultProps = {
	direction: 'horizontal',
};

FormatGroup.propTypes = {
	name: string.isRequired,
	label: oneOfType([string, object]),
	direction: string,
	children: array,
};

export default FormatGroup;
