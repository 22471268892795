import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { InteractionType } from '@azure/msal-browser';
import { useIsAuthenticated, useMsalAuthentication } from '@azure/msal-react';
import { loadProfile } from 'core/store/authentication/auth.actions';
import { Login } from 'views';

import Unauthorized from 'components/Error/Unauthorized/Unauthorized';
import AdminLayout from 'components/Layouts/AdminLayout/AdminLayout';
import Loading from 'components/Loading/Loading';

import ProtectedRoutes from '../ProtectedRoutes/ProtectedRoutes';
import UnprotectedRoutes from '../UnprotectedRoutes/UnprotectedRoutes';

const AdminRouter = () => {
	useMsalAuthentication(InteractionType.Redirect);
	const isAuthenticated = useIsAuthenticated();
	const { authLoading, permissions, meInfoFetched } = useSelector(
		state => state.authReducer,
	);

	useEffect(() => {
		if (isAuthenticated && !meInfoFetched) {
			loadProfile();
		}
	}, [isAuthenticated, meInfoFetched]);

	return (
		<Routes>
			<Route element={<UnprotectedRoutes loggedIn={isAuthenticated} />}>
				<Route path='/login' element={<Login />} />
			</Route>

			{isAuthenticated ? (
				<Route element={<ProtectedRoutes loggedIn={isAuthenticated} />}>
					<Route
						path='/*'
						element={
							authLoading /*&& !meInfoFetched*/ ? (
								<Loading large centered />
							) : permissions.includes('admin') ? (
								<AdminLayout />
							) : (
								<Unauthorized />
							)
						}
					/>
				</Route>
			) : null}
		</Routes>
	);
};

export default AdminRouter;
