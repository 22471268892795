import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { rolesFormData } from 'core/helpers/roles/roles.form-data';
import { useSubmit } from 'core/hooks/submit/useSubmit';
import { loadRoles } from 'core/store/application/application.actions';
import {
	loadEmployees,
	updateRoles,
} from 'core/store/employees/employee.actions';

import { Button, Form } from 'components/Form';
import { AdminHeader } from 'components/Layouts/AdminLayout/AdminHeader/AdminHeader';
import Loading from 'components/Loading/Loading';

import './Roles.scss';

const Roles = () => {
	const dispatch = useDispatch();
	const { handleSubmit } = useSubmit();

	const { roles, rolesLoading } = useSelector(
		state => state.applicationReducer,
	);
	const { employees, employeesLoading } = useSelector(
		state => state.employeeReducer,
	);

	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		dispatch(loadEmployees());
		dispatch(loadRoles());
	}, [dispatch]);

	const handleUpdate = async value => {
		await handleSubmit(updateRoles(value));
	};

	return (
		<>
			<AdminHeader title='Gebruikersrollen beheren' />

			<section className='roles__body'>
				{employeesLoading || rolesLoading ? (
					<Loading centered large />
				) : (
					roles
						?.filter(x => x.name !== 'instructor')
						.map(role => {
							const emp = employees?.filter(
								x => x.roleId === role.id,
							);

							return (
								<div key={`role-${role.id}`}>
									<h2>{role.displayName}</h2>
									{emp.length ? (
										<div className='roles__container'>
											{emp?.map(e => (
												<Button
													buttonStyle='link'
													onClick={() =>
														setShowModal({
															userId: e.id,
															roleId: e.roleId,
														})
													}
													className='roles__user'
													key={e.id}>
													<span>
														{e.firstName}{' '}
														{e.lastName}
													</span>

													<FontAwesomeIcon
														icon={faUserEdit}
														fixedWidth
													/>
												</Button>
											))}
										</div>
									) : (
										<div
											className='empty-list'
											style={{
												margin: '0 0 1.5rem',
												textAlign: 'left',
												fontSize: '2rem',
											}}>
											Geen data om weer te geven
										</div>
									)}
								</div>
							);
						})
				)}

				{showModal ? (
					<Form
						fields={rolesFormData(roles, showModal)}
						submitOnClick={handleUpdate}
						showModal={showModal !== false}
						setShowModal={setShowModal}
						modalSize='small'
						addCancel
						isModal
					/>
				) : null}
			</section>
		</>
	);
};

export default Roles;
