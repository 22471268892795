import { Link, useNavigate } from 'react-router-dom';
import {
	faEnvelopesBulk,
	faGlobe,
	faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSubmit } from 'core/hooks/submit/useSubmit';
import { useTheme } from 'core/hooks/ThemeProvider';
import { sendNewsletter } from 'core/store/application/application.actions';
import msalInstance from 'msalInstance';

import Toggle from 'components/Toggle/Toggle';

import './TopBar.scss';

const TopBar = ({ permissions }) => {
	const navigateTo = useNavigate();
	const { handleSubmit } = useSubmit();
	const { theme, mode, toggleTheme } = useTheme();

	const logout = async () => {
		try {
			await msalInstance.logoutPopup();

			navigateTo('/login');
		} catch (e) {
			console.log(e);
		}
	};

	const send = async () => {
		await handleSubmit(sendNewsletter());
	};

	return (
		<header className='navbar'>
			<Link to='/' className='navbar__brand'>
				<svg
					id='Laag_1'
					data-name='Laag 1'
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 1647.72 141.73'>
					<title>up-up-logotype-zonder-baseline-rood</title>
					<path
						d='M212.89,103.57q7.59,7.8,19.27,7.79,11.49,0,18.59-7.69t7.11-20.73V2.73h35.63V82.94q0,17.72-7.5,31.05a50.78,50.78,0,0,1-21.41,20.54q-13.92,7.2-32.61,7.2t-32.81-7.2A51.63,51.63,0,0,1,177.36,114q-7.7-13.33-7.69-31V2.73h35.62V82.94Q205.29,95.78,212.89,103.57Z'
						fill='#86181d'
					/>
					<path
						d='M415.94,2.73H449V139.2H417.89L356,57.63V139.2H322.69V2.73H354l62.11,82.15Z'
						fill='#86181d'
					/>
					<path d='M480.58,2.73h35.63V139.2H480.58Z' fill='#86181d' />
					<path
						d='M648,15.28q14.4,12.56,14.4,35.34,0,24.14-14.4,37.48t-40.5,13.33H583.18V139.2H547.55V2.73h60Q633.6,2.73,648,15.28ZM622.6,67.56q5.94-5.46,5.94-15.77,0-9.93-5.94-15.29t-16.84-5.35H583.18V73h22.58Q616.67,73,622.6,67.56Z'
						fill='#86181d'
					/>
					<path
						d='M774.75,139.2l-9.35-24.14h-59l-9.54,24.14h-36.6L718.87,2.73h36.6L812.71,139.2ZM717.12,87.8h37.77L736.2,39.13Z'
						fill='#86181d'
					/>
					<path
						d='M911.81,139.2,892,101.43H865.47V139.2H829.84V2.73h61.33q27.06,0,42,12.55T948,50.62q0,15.38-6.23,26.57a43.05,43.05,0,0,1-18.11,17.43l28.43,44.58ZM865.47,73h25.89q10.9,0,16.84-5.45t5.94-15.77q0-9.93-5.94-15.29t-16.84-5.35H865.47Z'
						fill='#86181d'
					/>
					<path
						d='M963.79,2.73h117V32.12h-40.88V139.2h-35.63V32.12H963.79Z'
						fill='#86181d'
					/>
					<path
						d='M1193.32,2.73h33.1V139.2h-31.15l-61.91-81.57V139.2h-33.29V2.73h31.34l62.11,82.15Z'
						fill='#86181d'
					/>
					<path
						d='M1258,2.73h107.66V31.15h-72v25.5h65V84.88l-65,.2v25.7h74V139.2H1258Z'
						fill='#86181d'
					/>
					<path
						d='M1476.2,139.2l-19.86-37.77h-26.48V139.2h-35.62V2.73h61.32q27.06,0,42,12.55t14.89,35.34q0,15.38-6.23,26.57a43.05,43.05,0,0,1-18.11,17.43l28.43,44.58ZM1429.86,73h25.9q10.89,0,16.84-5.45t5.94-15.77q0-9.93-5.94-15.29t-16.84-5.35h-25.9Z'
						fill='#86181d'
					/>
					<path
						d='M1610.82,33.29q-12.36-4.08-20.73-4.09-6.61,0-10.42,2.44a7.91,7.91,0,0,0-3.79,7.1,9.72,9.72,0,0,0,3.31,7.5,25.44,25.44,0,0,0,8.17,4.87q4.88,1.85,14.41,4.77a188.07,188.07,0,0,1,23.46,8.17,41.14,41.14,0,0,1,15.87,12.56q6.62,8.48,6.62,22.1T1640.51,122a43.68,43.68,0,0,1-19.76,14.51,76.86,76.86,0,0,1-27.94,4.86A100.89,100.89,0,0,1,1559,135.4a92.28,92.28,0,0,1-29.11-16.25l13.82-27.84a86,86,0,0,0,24.44,15q14.1,5.83,25,5.84,8,0,12.56-2.92a9.54,9.54,0,0,0,4.58-8.57,10.1,10.1,0,0,0-3.41-7.78,25.1,25.1,0,0,0-8.57-5q-5.16-1.84-14.5-4.38a178.06,178.06,0,0,1-23.17-7.88A41.07,41.07,0,0,1,1545,63.57q-6.52-8.09-6.52-21.52a37.62,37.62,0,0,1,6.71-22.19q6.72-9.54,19.08-14.7T1593,0a104.22,104.22,0,0,1,29.2,4.19,96.13,96.13,0,0,1,25.31,11.19L1634.09,43.8A120.27,120.27,0,0,0,1610.82,33.29Z'
						fill='#86181d'
					/>
					<path
						d='M0,13.19l32.81,34v94.56H31.63A31.63,31.63,0,0,1,0,110.1Z'
						fill='#86181d'
					/>
					<path
						d='M136.85,126.92,104,92.94V0h1.18a31.63,31.63,0,0,1,31.63,31.63Z'
						fill='#86181d'
					/>
					<path
						d='M84.83,74.27V0h0A32.78,32.78,0,0,0,52,32.78v7.51Z'
						fill='#86181d'
					/>
					<path
						d='M52,67.46v74.27h0a32.81,32.81,0,0,0,32.81-32.81v-7.47Z'
						fill='#86181d'
					/>
				</svg>
			</Link>

			<ul className='navbar__nav'>
				{/*<li className='navbar__nav-item'>
					<FontAwesomeIcon
						icon={faMagnifyingGlass}
						className='icon'
						fixedWidth
					/>
				</li>*/}
				{permissions.includes('send_newsletter') ? (
					<li className='navbar__nav-item'>
						<button
							onClick={send}
							className='navbar__nav-link'
							aria-label='Trigger newsletter'>
							<FontAwesomeIcon
								icon={faEnvelopesBulk}
								className='icon'
								fixedWidth
							/>
						</button>
					</li>
				) : null}
				<li className='navbar__nav-item'>
					<Toggle
						theme={theme}
						mode={mode}
						toggleTheme={toggleTheme}
					/>
				</li>
				<li className='navbar__nav-item'>
					<a
						href='https://ketchup.unipartners.org'
						target='_blank'
						rel='noreferrer'
						className='navbar__nav-link'
						aria-label='Go to KetchUP'>
						<FontAwesomeIcon
							icon={faGlobe}
							className='icon'
							fixedWidth
						/>
					</a>
				</li>
				<li className='navbar__nav-item'>
					<button
						onClick={logout}
						className='navbar__nav-link'
						aria-label='Log out'>
						<FontAwesomeIcon
							icon={faSignOutAlt}
							className='icon'
							fixedWidth
						/>
					</button>
				</li>
			</ul>
		</header>
	);
};

export default TopBar;
