import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import App from 'App';
import { SubmitProvider } from 'core/hooks/submit/useSubmit';
import { ThemeProvider } from 'core/hooks/ThemeProvider';
import { store } from 'core/store/store.config';

import msalInstance from './msalInstance.js';

import './assets/scss/app.scss';

await msalInstance.initialize();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<MsalProvider instance={msalInstance}>
		<Provider store={store}>
			<ThemeProvider>
				<SubmitProvider>
					<App />
				</SubmitProvider>
			</ThemeProvider>
		</Provider>
	</MsalProvider>,
);
