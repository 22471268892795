import { parseToDecimal } from 'core/services/parse';
import {
	arrayOf,
	bool,
	number,
	object,
	oneOfType,
	shape,
	string,
} from 'prop-types';

const Bar = ({ name, data, showLegend, singleGraph }) => {
	let left = 0;

	return (
		<div>
			{singleGraph ? (
				<div className='graphic__bar'>
					<div className='graphic__bar-wrapper'>
						<div
							className={`graphic__bar-bar${singleGraph ? ' single-graph' : ''}`}>
							{data
								.filter(({ value }) => value > 0)
								.map(({ className, base, value }, i) => {
									const percentage = (value / base) * 100;
									left += percentage;

									return (
										<div
											key={`${name}_${i}`}
											className={`graphic__bar-bar--value${className ? ` ${className}` : ''}`}
											style={{
												left: `${left - percentage}%`,
												width: `${percentage}%`,
											}}>
											{percentage >= 20 ? (
												<div
													className='graphic__bar-bar--percent inverted'
													style={{
														left: `0`,
													}}>
													{parseToDecimal(
														percentage,
														1,
														',',
													)}
													%
												</div>
											) : (
												''
											)}
										</div>
									);
								})}
						</div>
					</div>
				</div>
			) : (
				data.map(({ label, base, value, className }, i) => {
					const percentage = value === 0 ? 0 : (value / base) * 100;

					return (
						<div key={`${name}_${i}`} className='graphic__bar'>
							{showLegend ? `${value} / ${base}` : ''}
							<div className='graphic__bar-wrapper'>
								{label ? (
									<div className='graphic__bar-wrapper--label'>
										{label}
									</div>
								) : (
									''
								)}
								<div className='graphic__bar-bar'>
									<div
										className={`graphic__bar-bar--value${className ? ` ${className}` : ''}`}
										style={{ width: `${percentage}%` }}
									/>
									<div
										className={`graphic__bar-bar--percent${percentage > 20 ? ' inverted' : ''}`}
										style={{
											left:
												percentage > 20
													? `calc(${percentage}% - 6rem)`
													: `${percentage}%`,
										}}>
										{parseToDecimal(percentage, 1, ',')}%
									</div>
								</div>
							</div>
						</div>
					);
				})
			)}
		</div>
	);
};

Bar.defaultProps = {
	showLegend: false,
	singleGraph: false,
};

Bar.propTypes = {
	name: string.isRequired,
	data: arrayOf(
		shape({
			base: number.isRequired,
			value: number.isRequired,
			label: oneOfType([string, object]),
			className: string,
		}),
	).isRequired,
	showLegend: bool,
	singleGraph: bool,
};

export default Bar;
