import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { object, string } from 'prop-types';

const StatisticOne = ({ title, content, icon }) => {
	return (
		<div className='static__one'>
			<div className='static__one--logo'>
				<FontAwesomeIcon icon={icon} fixedWidth />
			</div>
			<div className='static__one--content'>
				<h3>{title}</h3>
				{content}
			</div>
		</div>
	);
};

StatisticOne.propTypes = {
	title: string.isRequired,
	content: string.isRequired,
	icon: object.isRequired,
};

export default StatisticOne;
