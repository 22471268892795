import React from 'react';
import { NavLink } from 'react-router-dom';
import {
	faListCheck,
	faPenToSquare,
	faPowerOff,
	faStar,
	faThumbtack,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from 'components/Form';

import './ListItem.scss';

export const ListItem = ({
	id,
	title,
	slug,
	showDeleteIcon,
	showPublishIcon,
	showResultsIcon,
	pin,
	important,
	publishedAt,
	isImportant,
	isPinned,
	children,
	setModal,
}) => {
	return (
		<section className='list-item'>
			<div className='list-item__header'>
				<h2>{title}</h2>

				<section className='list-item__icons btn__group'>
					{showResultsIcon ? (
						<NavLink
							to={`${slug}/registraties`}
							className='btn btn--primary btn--small'
							style={{ marginRight: '1rem' }}
							aria-label={`Bekijk inschrijvingen van ${title}`}>
							<FontAwesomeIcon
								icon={faListCheck}
								className='icon'
							/>
						</NavLink>
					) : null}
					{slug ? (
						<NavLink
							to={`${slug}`}
							className='btn btn--secondary btn--small'
							aria-label={`Wijzig ${title}`}>
							<FontAwesomeIcon
								icon={faPenToSquare}
								className='icon'
							/>
						</NavLink>
					) : null}
					{showPublishIcon ? (
						<Button
							buttonStyle='secondary'
							buttonSize='small'
							className={publishedAt ? ' success' : ''}
							onClick={() => setModal({ action: 'publish', id })}
							ariaLabel={`${publishedAt ? 'Maak publicatie ongedaan voor' : 'Publiseer'} ${title}`}>
							<FontAwesomeIcon
								icon={faPowerOff}
								className='icon'
							/>
						</Button>
					) : null}
					{pin ? (
						<Button
							buttonStyle='secondary'
							buttonSize='small'
							className={isPinned ? ' success' : ''}
							onClick={pin}
							ariaLabel={`${isPinned ? 'Maak pin ongedaan voor' : 'Pin'} ${title}`}>
							<FontAwesomeIcon
								icon={faThumbtack}
								className='icon'
							/>
						</Button>
					) : null}
					{important ? (
						<Button
							buttonStyle='secondary'
							buttonSize='small'
							className={isImportant ? ' success' : ''}
							onClick={important}
							ariaLabel={`Maak ${title} ${isImportant ? 'onbelangrijk' : 'belangrijk'}`}>
							<FontAwesomeIcon icon={faStar} className='icon' />
						</Button>
					) : null}
					{showDeleteIcon ? (
						<Button
							buttonStyle='secondary'
							buttonSize='small'
							className='error'
							onClick={() => setModal({ action: 'delete', id })}
							ariaLabel={`Verwijder ${title}`}>
							<FontAwesomeIcon icon={faTrash} className='icon' />
						</Button>
					) : null}
				</section>
			</div>

			{children}
		</section>
	);
};
