export const createFormData = data => {
	const formData = new FormData();

	for (const key in data) {
		parseObject(data[key], key, formData);
	}

	return formData;
};

const parseObject = (obj, parentKey, formData) => {
	if (obj && typeof obj === 'object') {
		Object.keys(obj).forEach(key => {
			if (obj[key] instanceof File) {
				formData.append(parentKey, obj[key]);

				const newKey = parentKey ? `${parentKey}_options[${key}]` : key;
				parseObject(
					{
						primary: obj[key].primary,
						attachment: obj[key].attachment,
					},
					newKey,
					formData,
				);
			} else {
				const newKey = parentKey ? `${parentKey}[${key}]` : key;
				parseObject(obj[key], newKey, formData);
			}
		});
	} else {
		if (typeof obj === 'boolean') {
			formData.append(parentKey, obj ? 1 : 0);
		} else {
			formData.append(parentKey, obj ? obj : '');
		}
	}
};
