import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';
import { successMessageService } from 'core/services/message';

export const loadMovingMissions = () => () => {
	dispatchAction({
		type: ACTION_TYPES.MOVING_MISSIONS_LOAD,
		payload: { movingMissionLoading: true },
		meta: {
			action: {
				effect: {
					url: `/admin/moving-mission`,
					method: 'get',
				},
				commit: { type: ACTION_TYPES.MOVING_MISSIONS_LOAD_COMMIT },
			},
			followup: [],
		},
	});
};

export const loadMovingMission = id => () => {
	dispatchAction({
		type: ACTION_TYPES.MOVING_MISSION_LOAD,
		payload: { movingMissionLoading: true },
		meta: {
			action: {
				effect: {
					url: `/admin/moving-mission/${id}`,
					method: 'get',
				},
				commit: { type: ACTION_TYPES.MOVING_MISSION_LOAD_COMMIT },
			},
			followup: [],
		},
	});
};

export const postMovingMission = (body, id, navigate) => () => {
	dispatchAction({
		type: ACTION_TYPES.MOVING_MISSION_POST,
		payload: {},
		meta: {
			action: {
				effect: {
					url: `/admin/moving-mission${id ? `/${id}` : ''}`,
					method: id ? 'put' : 'post',
					body: body,
				},
				commit: { type: ACTION_TYPES.MOVING_MISSION_POST_COMMIT },
			},
			toast: {
				type: 'success',
				text: successMessageService(
					'moving-mission',
					id ? 'updated' : 'created',
				),
			},
			followup: [() => navigate(`/moving-mission`)],
		},
	});
};

export const deleteMovingMission = id => () => {
	dispatchAction({
		type: ACTION_TYPES.MOVING_MISSION_DELETE,
		payload: {},
		meta: {
			action: {
				effect: {
					url: `/admin/moving-mission/${id}`,
					method: 'delete',
				},
				commit: {
					type: ACTION_TYPES.MOVING_MISSION_DELETE_COMMIT,
					payload: { id },
				},
			},
			toast: {
				type: 'success',
				text: successMessageService('moving-mission', 'deleted'),
			},
			followup: [],
		},
	});
};

export const loadRegistrations =
	(id, endDate = null, startDate = null) =>
	() => {
		dispatchAction({
			type: ACTION_TYPES.MOVING_MISSION_LOAD_REGISTRATIONS,
			payload: { movingMissionLoading: true },
			meta: {
				action: {
					effect: {
						url: `/admin/moving-mission/${id}/registrations${endDate ? `?endDate=${endDate}${startDate ? `&startDate=${startDate}` : ''}` : ''}`,
						method: 'get',
					},
					commit: {
						type: ACTION_TYPES.MOVING_MISSION_LOAD_REGISTRATIONS_COMMIT,
					},
				},
				followup: [],
			},
		});
	};

export const loadSportDetails = (missionId, sportId) => () => {
	dispatchAction({
		type: ACTION_TYPES.MOVING_MISSION_LOAD_DETAIL,
		payload: { detailLoading: true },
		meta: {
			action: {
				effect: {
					url: `/admin/moving-mission/${missionId}/sport/${sportId}`,
					method: 'get',
				},
				commit: {
					type: ACTION_TYPES.MOVING_MISSION_LOAD_DETAIL_COMMIT,
				},
			},
			followup: [],
		},
	});
};

export const loadContactDetails = (missionId, contactId) => () => {
	dispatchAction({
		type: ACTION_TYPES.MOVING_MISSION_LOAD_DETAIL,
		payload: { detailLoading: true },
		meta: {
			action: {
				effect: {
					url: `/admin/moving-mission/${missionId}/user/${contactId}`,
					method: 'get',
				},
				commit: {
					type: ACTION_TYPES.MOVING_MISSION_LOAD_DETAIL_COMMIT,
				},
			},
			followup: [],
		},
	});
};
