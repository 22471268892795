import { useEffect, useRef } from 'react';

const Pie = ({ name, data, showLegend }) => {
	const canvasRef = useRef(null);

	useEffect(() => {
		const canvas = canvasRef.current;
		const ctx = canvas.getContext('2d');
		const total = data.reduce((sum, value) => sum + value.value, 0);
		let startAngle = 0;

		data.forEach(value => {
			const sliceAngle = (value.value / total) * 2 * Math.PI;
			ctx.beginPath();
			ctx.moveTo(150, 150); // center of the canvas
			ctx.arc(150, 150, 150, startAngle, startAngle + sliceAngle);
			ctx.closePath();
			ctx.fillStyle = value.color;
			ctx.fill();
			startAngle += sliceAngle;
		});
	}, [data]);

	return (
		<div key={`${name}`} className='graphic__pie'>
			<div className='graphic__pie-wrapper'>
				<div className='graphic__pie-label'>
					{showLegend
						? data.map(({ label, value, base }, i) => (
								<div key={`${label}-${i}`}>
									{label}
									<span>
										{value} / {base}
									</span>
								</div>
							))
						: null}
				</div>
				<div className='graphic__pie-pie'>
					<canvas ref={canvasRef} width={300} height={300} />
				</div>
			</div>
		</div>
	);
};

export default Pie;
