import { useState } from 'react';
import { slugify } from 'core/helpers/slugify';
import { useOnlineStatus } from 'core/hooks/OnlineStatusProvider';

/**
 * A `react-hook-form` like hook for handling form state.
 * @param {Object} options - The options of the hook.
 * @param {boolean} options.disableWhenOffline - Whether the form should be disabled when offline.
 * @example
 * const { values, register, valid } = useForm();
 * <Form onSubmit={handleSubmit}>
 * 	<InputField {...register('firstName', { value: 'John' })} />
 * 	<InputField {...register('lastName', { value: 'Doe' })} />
 * 	<Button type='submit' disabled={!valid()}>
 * 		Submit
 * 	</Button>
 * </Form>
 */
export const useForm = ({ disableWhenOffline = true } = {}) => {
	const [fields, setFields] = useState([]);

	const online = useOnlineStatus();

	/**
	 * Register a field in the form.
	 * @param {string} fieldName - The name of the field.
	 * @param {FormField} options - The options of the field.
	 * @returns {UpdatedFormField}- The updated field.
	 * @example
	 * const { register } = useForm();
	 * <InputField {...register('firstName', { value: 'John' })} />
	 */
	const register = (fieldName, options) => {
		const { label, validations = [] } = options;
		const id = slugify(options.name);

		if (!Object.prototype.hasOwnProperty.call(fields, fieldName)) {
			setFields(prev => ({
				...prev,
				[fieldName]: {
					...options,
					id: id,
					value: options.value ?? '',
					errors: validate(options.value),
					focused: false,
					touched: false,
				},
			}));
		}

		const validate = value => {
			const errors = [];
			validations.map(validate => {
				const string = validate(value, label);
				if (string.length) {
					errors.push(string);
				}
			});

			return errors;
		};

		const onChange = value => {
			setFields(prevState => ({
				...prevState,
				[fieldName]: {
					...prevState[fieldName],
					value: value,
					errors:
						options.type === 'file'
							? prevState[fieldName].errors
							: validate(value),
				},
			}));
		};

		const setTouched = boolean => {
			setFields(prevState => ({
				...prevState,
				[fieldName]: {
					...prevState[fieldName],
					touched: boolean,
				},
			}));
		};

		const setFocused = boolean => {
			setFields(prevState => ({
				...prevState,
				[fieldName]: {
					...prevState[fieldName],
					focused: boolean,
				},
			}));
		};

		return {
			...options,
			id: id,
			touched: fields[fieldName]?.touched,
			focused: fields[fieldName]?.focused,
			disabled: options.disabled || (!online && disableWhenOffline),
			value: fields[fieldName]?.value,
			errors: fields[fieldName]?.errors,
			key: fieldName,
			onChange,
			onError:
				options.type === 'file'
					? error => (fields[fieldName].errors = error)
					: undefined,
			setTouched,
			setFocused,
		};
	};

	const reset = () => {
		setFields([]);
	};

	const setValue = (fieldName, value) => {
		setFields(prev => ({
			...prev,
			[fieldName]: {
				...prev[fieldName],
				value: value,
				errors: validate(fieldName, value),
			},
		}));
	};

	const validate = (fieldName, value) => {
		const field = fields[fieldName];
		const errors = [];

		field.validations?.map(validate => {
			const string = validate(value, fieldName);
			if (string.length) {
				errors.push(string);
			}
		});

		return errors;
	};

	const getValues = () => {
		let object = {};
		Object.keys(fields).map(
			key => (object[key] = fields[key].value ?? null),
		);
		return object;
	};

	const getValue = fieldName => {
		return fields[fieldName]?.value || null;
	};

	const isValid = () => {
		return (
			Object.keys(fields)
				.map(key => fields[key].errors?.length > 0)
				.indexOf(true) === -1
		);
	};

	const getField = name => {
		return fields[name];
	};

	return {
		register,
		setValue,
		isValid,
		reset,
		getValues,
		getValue,
		getField,
	};
};

/**
 * @typedef {Object} FormField
 * @property {string} type - The type of the field.
 * @property {string} name - The name of the field.
 * @property {string} label - The label of the field.
 * @property {string} placeholder - The placeholder of the field.
 * @property {string|object} description - The description of the field.
 * @property {boolean} required - Whether the field is required.
 * @property {boolean} readOnly - Whether the field is read only.
 * @property {boolean} disabled - Whether the field is disabled.
 * @property {string} autocomplete - The autocomplete of the field.
 * @property {Function[]} validations - The validations of the field.
 * @property {string[]} errors - The initial errors of the field.
 * @property {string} className - The className of the field.
 * @property {string} value - The initial value of the field.
 * @property {string} buttonIcon - The buttonIcon of the field.
 * @property {string} icon - The icon of the field.
 */

/**
 * @typedef {Object} ExtraProps
 * @property {Function} validate - The validate function of the field.
 * @property {Function} onChange - The onChange function of the field.
 * @property {string} key - The key of the field.
 */

/**
 * @typedef {FormField & ExtraProps} UpdatedFormField
 */
