import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

let defaultState = {
	employees: [],
	employeesLoading: false,
};

const loadEmployees = (state, { employeesLoading }) => ({
	...state,
	employeesLoading,
});

const loadEmployeesCommit = (state, payload) => ({
	...state,
	employeesLoading: false,
	employees: payload,
});

const putUserRole = (state, { roles: { user_id }, data }) => {
	console.log(user_id, data);
	return {
		...state,
		employees: state.employees.map(x => {
			if (x.id !== user_id) return x;

			return {
				...x,
				roleId: data.data,
			};
		}),
	};
};

export const employeeReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.EMPLOYEES_LOAD:
			return loadEmployees(state, payload);
		case ACTION_TYPES.EMPLOYEES_LOAD_COMMIT:
			return loadEmployeesCommit(state, payload.data);
		case ACTION_TYPES.ROLES_PUT_COMMIT:
			return putUserRole(state, payload);
		default:
			return state;
	}
};
