import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { getEntityTypeDetails } from 'core/helpers/entities/entity.helper';
import { dispatchAction } from 'core/services/actions';
import { createFormData } from 'core/services/form';
import { successMessageService } from 'core/services/message';

export const loadEntities = (entity, page, filter) => () => {
	dispatchAction({
		type: ACTION_TYPES.ENTITIES_LOAD,
		payload: { entitiesLoading: true, currentPage: page },
		meta: {
			action: {
				effect: {
					url: `/admin/${entity}?page=${page}&filter=${filter.name}`,
					method: 'get',
				},
				commit: { type: ACTION_TYPES.ENTITIES_LOAD_COMMIT },
			},
			followup: [],
		},
	});
};

export const loadEntity = (entity, entityId) => () => {
	dispatchAction({
		type: ACTION_TYPES.ENTITY_LOAD,
		payload: { entitiesLoading: true, entity: null },
		meta: {
			action: {
				effect: {
					url: `/${entity}/${entityId}`,
					method: 'get',
				},
				commit: { type: ACTION_TYPES.ENTITY_LOAD_COMMIT },
			},
			followup: [],
		},
	});
};

export const loadEntityForm = (entity, entityId) => () => {
	dispatchAction({
		type: ACTION_TYPES.ENTITY_FORM_LOAD,
		payload: { form: null },
		meta: {
			action: {
				effect: {
					url: `/forms/${entity}/${entityId}`,
					method: 'get',
				},
				commit: { type: ACTION_TYPES.ENTITY_FORM_LOAD_COMMIT },
			},
			followup: [],
		},
	});
};

export const postEntity = (body, id, entity, navigate) => () => {
	dispatchAction({
		type: ACTION_TYPES.ENTITY_POST,
		payload: {},
		meta: {
			action: {
				effect: {
					url: `/admin/${entity}${id ? `/${id}` : ''}`,
					method: id ? 'put' : 'post',
					body: createFormData(body),
				},
				commit: { type: ACTION_TYPES.ENTITY_POST_COMMIT },
			},
			toast: {
				type: 'success',
				text: successMessageService(entity, id ? 'updated' : 'created'),
			},
			followup: [() => navigate(`/${getEntityTypeDetails(entity).name}`)],
		},
	});
};

export const putEntity = (entity, action, id) => () => {
	dispatchAction({
		type: ACTION_TYPES.ENTITY_PUT,
		payload: {},
		meta: {
			action: {
				effect: {
					url: `/admin/${entity}/${id}${action ? `/${action}` : ''}`,
					method: 'put',
				},
				commit: { type: ACTION_TYPES.ENTITY_PUT_COMMIT },
			},
			toast: {
				type: 'success',
				text: successMessageService(entity, 'updated'),
			},
			followup: [],
		},
	});
};

export const deleteEntity = (entity, id) => () => {
	dispatchAction({
		type: ACTION_TYPES.ENTITY_DELETE,
		payload: {},
		meta: {
			action: {
				effect: {
					url: `/admin/${entity}/${id}`,
					method: 'delete',
				},
				commit: {
					type: ACTION_TYPES.ENTITY_DELETE_COMMIT,
					payload: { entity, id },
				},
			},
			toast: {
				type: 'success',
				text: successMessageService(entity, 'deleted'),
			},
			followup: [],
		},
	});
};

export const loadRegistrations = (entity, id) => () => {
	dispatchAction({
		type: ACTION_TYPES.ENTITY_LOAD_REGISTRATIONS,
		payload: { entitiesLoading: true },
		meta: {
			action: {
				effect: {
					url: `/admin/${entity}/${id}/registrations`,
					method: 'get',
				},
				commit: {
					type: ACTION_TYPES.ENTITY_LOAD_REGISTRATIONS_COMMIT,
				},
			},
			followup: [],
		},
	});
};
