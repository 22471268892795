import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

let defaultState = {
	wellBeings: [],
	wellBeing: null,
	registrations: {},
	wellBeingLoading: false,
};

const loadBarometers = (state, { wellBeingLoading }) => ({
	...state,
	wellBeingLoading,
	wellBeing: null,
	registrations: {},
});

const loadBarometersCommit = (state, { data }) => ({
	...state,
	wellBeings: data,
	wellBeingLoading: false,
});

const updateWellBeings = (state, { userId }) => {
	return {
		...state,
		registrations: {
			...state.registrations,
			[userId]: {
				...state.registrations[userId],
				contacted: !state.registrations[userId].contacted,
			},
		},
	};
};

const loadWellBeing = (state, { wellBeingLoading, wellBeing }) => ({
	...state,
	wellBeingLoading,
	wellBeing,
});

const loadWellBeingCommit = (state, payload) => ({
	...state,
	wellBeing: payload,
	wellBeingLoading: false,
});

const deleteWellBeing = (state, { id }) => ({
	...state,
	wellBeings: state.wellBeings.filter(item => item.id !== id),
});

const loadRegistrations = (state, { wellBeingLoading }) => ({
	...state,
	wellBeingLoading,
});

const loadRegistrationsCommit = (state, payload) => ({
	...state,
	registrations: payload,
	wellBeingLoading: false,
});

export const WellBeingReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.WELL_BEINGS_LOAD:
			return loadBarometers(state, payload);
		case ACTION_TYPES.WELL_BEINGS_LOAD_COMMIT:
			return loadBarometersCommit(state, payload);
		case ACTION_TYPES.WELL_BEING_PUT_COMMIT:
			return updateWellBeings(state, payload);
		case ACTION_TYPES.WELL_BEING_LOAD:
			return loadWellBeing(state, payload);
		case ACTION_TYPES.WELL_BEING_LOAD_COMMIT:
			return loadWellBeingCommit(state, payload.data);
		case ACTION_TYPES.WELL_BEING_DELETE_COMMIT:
			return deleteWellBeing(state, payload);
		case ACTION_TYPES.WELL_BEING_LOAD_REGISTRATIONS:
			return loadRegistrations(state, payload);
		case ACTION_TYPES.WELL_BEING_LOAD_REGISTRATIONS_COMMIT:
			return loadRegistrationsCommit(state, payload.data);
		default:
			return state;
	}
};
