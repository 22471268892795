import { PropTypes } from 'prop-types';

import { Col } from 'components/Grid';

const Content = ({ hasSidebar, children }) => (
	<Col
		lg={hasSidebar ? 8 : 12}
		md={hasSidebar ? 7 : 12}
		mdOrder={1}
		smOrder={2}>
		{children}
	</Col>
);

Content.defaultProps = {
	hasSidebar: false,
};

Content.propTypes = {
	hasSidebar: PropTypes.bool,
	children: PropTypes.node.isRequired,
};

export default Content;
