import { useDrag } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFormDetail } from 'core/helpers/form-builder/form-builder.helper';

const DefaultForm = ({ type, addForm }) => {
	const field = getFormDetail(type);

	const [{ isDragging }, drag] = useDrag(() => ({
		type: 'FORM',
		item: { type, id: 0 },
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	}));

	return (
		<div
			ref={drag}
			onClick={() => addForm(type)}
			className={`form-builder__available-field${isDragging ? ' dragging' : ''}`}>
			<FontAwesomeIcon icon={field.icon} fixedWidth />
			{field.name}
		</div>
	);
};

export default DefaultForm;
