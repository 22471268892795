import { useState } from 'react';
import {
	faChevronDown,
	faChevronUp,
	faStopwatch20,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toShortDate } from 'core/services/date';

import Attachments from 'components/Attachment/Attachment';

import './History.scss';

const History = ({ date, duration, sport, icon, attachments }) => {
	const [showDetails, setShowDetails] = useState(false);

	const toggleDetails = e => {
		e.stopPropagation();
		setShowDetails(!showDetails);
	};

	return (
		<div className='history__item'>
			<div
				className='history__item-header'
				onClick={attachments.length ? toggleDetails : null}>
				<div className='history__item-header-wrapper'>
					<div className='history__item-header-wrapper__title'>
						<FontAwesomeIcon icon={icon ?? faStopwatch20} />
						{sport}
					</div>

					<span className='history__item-header-wrapper__duration'>{`${toShortDate(date)}${duration > 0 ? ` - ${duration} minuten` : ''}`}</span>
				</div>

				{attachments.length ? (
					<FontAwesomeIcon
						icon={showDetails ? faChevronUp : faChevronDown}
					/>
				) : null}
			</div>
			{showDetails ? (
				<div className='history__item-content'>
					<Attachments attachments={attachments} />
				</div>
			) : null}
		</div>
	);
};

export default History;
