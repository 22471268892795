import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faMedal,
	fas,
	faStar,
	faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	movingMissionColors,
	movingMissionIcon,
} from 'core/helpers/moving-mission/moving-mission.helper';
import { loadSportTypes } from 'core/store/application/application.actions';
import {
	loadContactDetails,
	loadRegistrations,
	loadSportDetails,
} from 'core/store/moving-mission/moving-mission.actions';

import Card from 'components/Card/Card';
import Graphic from 'components/Graphic/Graphic';
import { Col, Row } from 'components/Grid';
import { AdminHeader } from 'components/Layouts/AdminLayout/AdminHeader/AdminHeader';
import Loading from 'components/Loading/Loading';
import { History } from 'components/MovingMission';
import CompanyActivity from 'components/MovingMission/CompanyActivity/CompanyActivity';

import './MovingMission.scss';

library.add(fas);

const MovingMissionResults = () => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const [selectedSport, setSelectedSport] = useState(null);
	const [selectedContact, setSelectedContact] = useState(null);
	const [chartData, setChartData] = useState([]);

	const { sports, sportsLoading } = useSelector(
		state => state.applicationReducer,
	);

	const { movingMissionLoading, registrations, detail, detailLoading } =
		useSelector(state => state.movingMissionReducer);

	useEffect(() => {
		dispatch(loadSportTypes());
		dispatch(loadRegistrations(id));
	}, []);

	useEffect(() => {
		if (selectedSport) dispatch(loadSportDetails(id, selectedSport));
	}, [selectedSport]);

	useEffect(() => {
		if (selectedContact) dispatch(loadContactDetails(id, selectedContact));
	}, [selectedContact]);

	useEffect(() => {
		if (selectedContact && detail?.topThree?.length)
			setChartData(
				detail.topThree.map(x => ({
					base: 100,
					value: x.percentage,
					label: (
						<>
							<FontAwesomeIcon
								icon={[
									'fas',
									movingMissionIcon(sports, x.type).icon,
								]}
								style={{
									color: movingMissionColors[
										x.type.toLowerCase()
									],
								}}
							/>{' '}
							{x.type}
						</>
					),
					color: movingMissionColors[x.type.toLowerCase()],
				})),
			);
	}, [selectedContact, detail]);

	return (
		<div className='moving-mission'>
			<AdminHeader title='Moving Mission' />

			{sportsLoading || movingMissionLoading ? (
				<Loading centered large />
			) : (
				<Row>
					<Col lg={6} md={6} className='streaks'>
						<Card title='Puntenklassement'>
							<div className='streaks__detail'>
								{selectedSport && detailLoading ? (
									<Loading />
								) : (
									<ul>
										{(selectedSport
											? detail?.pointsRanking
											: registrations.pointsRanking
										)?.length ? (
											(selectedSport
												? detail?.pointsRanking
												: registrations.pointsRanking
											)?.map((x, i) => (
												<li key={i}>
													<FontAwesomeIcon
														icon={faMedal}
														className={
															i === 0
																? 'gold'
																: i === 1
																	? 'silver'
																	: i === 2
																		? 'bronze'
																		: ''
														}
													/>
													{x.firstName} {x.lastName} -{' '}
													{x.points} points
												</li>
											))
										) : (
											<li
												className='empty-list'
												style={{ margin: '1.5rem 0' }}>
												Geen resultaten
											</li>
										)}
									</ul>
								)}

								{!selectedSport ? (
									<>
										<h2>Reeksklassement</h2>
										<ul>
											{registrations.streakRanking
												?.length ? (
												registrations.streakRanking?.map(
													(x, i) => (
														<li key={i}>
															<FontAwesomeIcon
																icon={faStar}
																className={
																	i === 0
																		? 'gold'
																		: i ===
																			  1
																			? 'silver'
																			: i ===
																				  2
																				? 'bronze'
																				: ''
																}
															/>
															{x.firstName}{' '}
															{x.lastName} -{' '}
															{x.streakDays} days
														</li>
													),
												)
											) : (
												<li
													className='empty-list'
													style={{
														margin: '1.5rem 0',
													}}>
													Geen resultaten
												</li>
											)}
										</ul>
									</>
								) : null}
							</div>
							<div className='streaks__sports'>
								{sports.map(sport => (
									<div
										key={`sport-${sport.id}`}
										className={`streaks__sports-item${selectedSport === sport.id ? ' selected' : ''}`}
										onClick={() => {
											setSelectedSport(
												selectedSport &&
													selectedSport === sport.id
													? null
													: sport.id,
											);
											setSelectedContact(null);
										}}>
										<FontAwesomeIcon
											icon={['fas', sport.icon]}
										/>
										<span>{sport.name}</span>
									</div>
								))}
							</div>
						</Card>
					</Col>

					<Col lg={3} md={6} className='employees'>
						{registrations.contestants?.map(contestant => (
							<div
								key={contestant.id}
								className={`employees__detail${selectedContact === contestant.id ? ' selected' : ''}`}
								onClick={() => {
									setSelectedContact(
										selectedContact &&
											selectedContact === contestant.id
											? null
											: contestant.id,
									);
									setSelectedSport(null);
								}}>
								<FontAwesomeIcon icon={faUser} />
								<div className='employees__detail-contact'>
									<div className='employees__detail-contact_name'>{`${contestant.firstName} ${contestant.lastName}`}</div>
									<div className='employees__detail-contact_department'>
										{contestant.department}
									</div>
								</div>
							</div>
						))}
					</Col>

					<Col lg={3} md={12}>
						{selectedContact && detailLoading ? (
							<Loading />
						) : selectedContact && detail ? (
							<div className='profile'>
								<div className='profile__info'>
									<FontAwesomeIcon icon={faUser} />
									<div className='profile__info-name'>{`${detail.user?.firstName} ${detail.user?.lastName}`}</div>
									<div className='profile__info-department'>
										{detail.user?.department}
									</div>
								</div>

								<Graphic
									data={chartData}
									name='pie-chart'
									type='pie'
									showLegend
								/>

								<div className='history'>
									<h3>Post History</h3>
									<div className='history__content'>
										{detail.history.map((post, index) => {
											const icon = sports.find(
												icon => icon.name === post.type,
											)?.icon;

											return (
												<History
													key={index}
													date={post.date}
													sport={post.type}
													duration={post.duration}
													icon={icon}
													attachments={
														post.attachments
													}
												/>
											);
										})}
									</div>
								</div>
							</div>
						) : null}
					</Col>

					<Col md={12} className='streaks'>
						<CompanyActivity />
					</Col>
				</Row>
			)}
		</div>
	);
};

export default MovingMissionResults;
