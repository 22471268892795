import { loginRequest } from '../../../authConfig';
import msalInstance from '../../../msalInstance';

export const getToken = async () => {
	try {
		const account = msalInstance.getActiveAccount();

		if (!account) {
			const allAccounts = msalInstance.getAllAccounts();

			if (allAccounts.length > 0) {
				msalInstance.setActiveAccount(allAccounts[0]);
			} else {
				console.log('No accounts found');
				return null;
			}
		}

		const { idToken } = await msalInstance.acquireTokenSilent({
			account,
			...loginRequest,
		});

		return idToken;
	} catch (error) {
		console.error('acquireTokenSilent failed:', error);
		throw error;
	}
};
