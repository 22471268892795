import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';

export const loadTags = () => () => {
	dispatchAction({
		type: ACTION_TYPES.TAGS_LOAD,
		payload: { tagsLoading: true },
		meta: {
			action: {
				effect: {
					url: '/admin/tags',
					method: 'get',
				},
				commit: { type: ACTION_TYPES.TAGS_LOAD_COMMIT },
			},
			followup: [],
		},
	});
};

export const addTag = tag => {
	dispatchAction({ type: ACTION_TYPES.TAGS_ADD, payload: { tag } });
};
