import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { movingMissionFormData } from 'core/helpers/moving-mission/moving-mission.form-data';
import { useSubmit } from 'core/hooks/submit/useSubmit';
import { postMovingMission } from 'core/store/moving-mission/moving-mission.actions';

import { Form } from 'components/Form';
import { Col, Row } from 'components/Grid';
import { AdminHeader } from 'components/Layouts/AdminLayout/AdminHeader/AdminHeader';

const MovingMissionForm = ({ object }) => {
	const { handleSubmit } = useSubmit();
	const navigate = useNavigate();

	const [form] = useState(movingMissionFormData(object));

	const submitForm = async temp => {
		await handleSubmit(postMovingMission(temp, object?.id, navigate));
	};

	return (
		<Row className='entity'>
			<Col md={12}>
				<AdminHeader title='Mission toevoegen' />

				<Form
					fields={form.form}
					layout={form.layout}
					submitOnClick={submitForm}
					cancelOnClick={() => {
						if (object) navigate(`/moving-mission`);
					}}
					cardTitle='Missie toevoegen'
					addCancel
					isCard
				/>
			</Col>
		</Row>
	);
};

export default MovingMissionForm;
