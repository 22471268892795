import React from 'react';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Container } from 'components/Grid';

import './NotFound.scss';

const NotFound = () => {
	return (
		<div className='notfound-error-wrapper'>
			<Container>
				<h4>Whoops! We missen wat bits en bytes!?</h4>
				<h1>404</h1>
				<p>
					Helaas, wat je zoekt is hier niet te vinden. Maar geen
					paniek, er is nog genoeg ander leuks te ontdekken!
				</p>
				<p>
					Denk je dat dit een fout is of heb je het vermoeden van een
					dode link? Neem contact op met{' '}
					<a href='mailto: support@unipartners.be'>
						support@unipartners.be
					</a>
					.
				</p>

				<a href='/' className='btn btn--secondary btn--block'>
					<FontAwesomeIcon icon={faHome} />
					<span>Terug naar de homepage</span>
				</a>
			</Container>
		</div>
	);
};

export default NotFound;
