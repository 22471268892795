import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';
import { successMessageService } from 'core/services/message';

export const loadBarometers = () => () => {
	dispatchAction({
		type: ACTION_TYPES.WELL_BEINGS_LOAD,
		payload: { wellBeingLoading: true },
		meta: {
			action: {
				effect: {
					url: `/admin/well-being`,
					method: 'get',
				},
				commit: { type: ACTION_TYPES.WELL_BEINGS_LOAD_COMMIT },
			},
			followup: [],
		},
	});
};

export const loadBarometer = id => () => {
	dispatchAction({
		type: ACTION_TYPES.WELL_BEING_LOAD,
		payload: { entitiesLoading: true, entity: null },
		meta: {
			action: {
				effect: {
					url: `/admin/well-being/${id}`,
					method: 'get',
				},
				commit: { type: ACTION_TYPES.WELL_BEING_LOAD_COMMIT },
			},
			followup: [],
		},
	});
};

export const postWellBeing = (body, id, navigate) => () => {
	dispatchAction({
		type: ACTION_TYPES.WELL_BEING_POST,
		payload: {},
		meta: {
			action: {
				effect: {
					url: `/admin/well-being${id ? `/${id}` : ''}`,
					method: id ? 'put' : 'post',
					body: body,
				},
				commit: { type: ACTION_TYPES.WELL_BEING_POST_COMMIT },
			},
			toast: {
				type: 'success',
				text: successMessageService(
					'well-being',
					id ? 'updated' : 'created',
				),
			},
			followup: [() => navigate(`/welzijn`)],
		},
	});
};

export const putBarometer = (barometerId, userId) => () => {
	dispatchAction({
		type: ACTION_TYPES.WELL_BEING_PUT,
		payload: {},
		meta: {
			action: {
				effect: {
					url: `/admin/well-being/${barometerId}/${userId}`,
					method: 'put',
				},
				commit: {
					type: ACTION_TYPES.WELL_BEING_PUT_COMMIT,
					payload: { userId },
				},
			},
			toast: {
				type: 'success',
				text: successMessageService('well-being', 'updated'),
			},
			followup: [],
		},
	});
};

export const deleteBarometer = id => () => {
	dispatchAction({
		type: ACTION_TYPES.WELL_BEING_DELETE,
		payload: {},
		meta: {
			action: {
				effect: {
					url: `/admin/well-being/${id}`,
					method: 'delete',
				},
				commit: {
					type: ACTION_TYPES.WELL_BEING_DELETE_COMMIT,
					payload: { id },
				},
			},
			toast: {
				type: 'success',
				text: successMessageService('well-being', 'deleted'),
			},
			followup: [],
		},
	});
};

export const loadRegistrations = id => () => {
	dispatchAction({
		type: ACTION_TYPES.WELL_BEING_LOAD_REGISTRATIONS,
		payload: { wellBeingLoading: true },
		meta: {
			action: {
				effect: {
					url: `/admin/well-being/${id}/registrations`,
					method: 'get',
				},
				commit: {
					type: ACTION_TYPES.WELL_BEING_LOAD_REGISTRATIONS_COMMIT,
				},
			},
			followup: [],
		},
	});
};
