export const isRequired = (val, label) => {
	const checkValue =
		typeof val === 'number' ||
		(!isNaN(val) && typeof val !== 'object') ||
		typeof val === 'boolean'
			? val
			: typeof val === 'string'
				? val?.trim().length
				: val?.length;

	if (checkValue < 0) {
		return `${label} moet een positief getal zijn.`;
	}
	return checkValue ? '' : `${label} is een verplicht veld.`;
};

export const isRequiredHtml = (val, label) => {
	const div = document.createElement('div');
	div.innerHTML = val;
	const text = div.textContent || div.innerText || '';

	return text.trim().length > 0 ? '' : `${label} is een verplicht veld.`;
};

export const isLargerThan = min => (val, label) => {
	return !val || +val >= min
		? ''
		: `${label} mag niet kleiner zijn dan ${min}.`;
};

export const isValidUrl = (val, label) => {
	const urlPattern = new RegExp(
		'^(https?:\\/\\/)' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$', // fragment locator
		'i',
	);
	return val === '' || !!urlPattern.test(val)
		? ''
		: `${label} is geen geldige url.`;
};

export const isValidEmail = (val, label) => {
	const isEmail = String(val)
		.toLowerCase()
		.match(
			/^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))?$/,
		);
	return val === '' || isEmail ? '' : `${label} is geen geldige e-mailadres.`;
};

export const isValidFile = file => {
	const validFileExtensions = [
		'txt',
		'xls',
		'xlsx',
		'doc',
		'docx',
		'ppt',
		'pptx',
		'pdf',
		'mpeg',
		'jpg',
		'jpeg',
		'png',
		'bmp',
		'gif',
		'mp4',
		'wav',
		'wave',
		'm4v',
	];
	const validFileMimes = [
		'text/plain',
		'application/xls',
		'application/vnd.ms-excel',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/vnd.ms-powerpoint',
		'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		'application/pdf',
		'audio/mpeg',
		'image/jpeg',
		'image/png',
		'image/bmp',
		'image/gif',
		'video/mp4',
		'video/wav',
		'video/wave',
		'video/quicktime',
	];
	const fileExt = file.name.split('.').pop();

	return (
		validFileExtensions.some(x => x === fileExt) &&
		validFileMimes.some(x => x === file.type)
	);
};
