import { INPUT_TYPES } from 'core/constants';
import { isRequired, isValidEmail } from 'core/services/form';

export const instructorsFormData = () => {
	return {
		id: {
			type: INPUT_TYPES.HIDDEN,
			name: 'id',
			value: null,
		},
		first_name: {
			type: INPUT_TYPES.TEXT,
			name: 'first_name',
			label: 'Voornaam',
			required: true,
			validations: [isRequired],
			value: '',
		},
		last_name: {
			type: INPUT_TYPES.TEXT,
			name: 'last_name',
			label: 'Naam',
			required: true,
			validations: [isRequired],
			value: '',
		},
		email: {
			type: INPUT_TYPES.TEXT,
			name: 'email',
			label: 'E-mail',
			required: true,
			validations: [isRequired, isValidEmail],
			value: '',
		},
		access_coce: {
			type: INPUT_TYPES.CHECKBOX,
			name: 'access_coce',
			label: 'Toegang tot de coce-tool',
			value: false,
		},
	};
};
