import { useState } from 'react';
import { useDrop } from 'react-dnd';
import { INPUT_TYPES } from 'core/constants';
import {
	formBuilderCustomFormData,
	formBuilderFormData,
} from 'core/helpers/form-builder/form-builder.form-data';
import {
	defaultForms,
	getFieldSettings,
} from 'core/helpers/form-builder/form-builder.helper';

import { Button, Form } from 'components/Form';
import { Col, Row } from 'components/Grid';

import Field from './Field/Field';
import DefaultForm from './Form/DefaultForm';
import FormField from './FormField/FormField';

import './FormBuilder.scss';

const FormBuilder = ({ save, close, ...props }) => {
	const [formBuilder, setFormBuilder] = useState(props.form);
	const [showModal, setShowModal] = useState(false);
	const [showSettingsModal, setShowSettingsModal] = useState(false);
	const [settingsForm, setSettingsForm] = useState([]);

	const [, drop] = useDrop({
		accept: ['FIELD', 'FORM'],
		drop: (item, monitor) => {
			if (monitor.getItemType() === 'FORM') {
				addForm(item.type);
			} else {
				addField(item.type);
			}
		},
	});

	const [, removeDrop] = useDrop({
		accept: 'FIELD',
		drop: item => {
			removeField(item.id);
		},
	});

	const addField = fieldType => {
		if (fieldType) {
			setFormBuilder(prevState => ({
				...prevState,
				fields: [
					...prevState.fields,
					{
						type: fieldType,
						id: Date.now(),
						settings: getFieldSettings(
							fieldType,
							prevState.fields.length,
						),
					},
				],
			}));
		}
	};

	const addForm = formType => {
		setFormBuilder(defaultForms(formType));
	};

	const removeField = id => {
		setFormBuilder(prevState => ({
			...prevState,
			fields: prevState.fields.filter(field => field.id !== id),
		}));
	};

	const moveField = (dragIndex, hoverIndex) => {
		if (dragIndex) {
			const draggedField = formBuilder.fields[dragIndex];
			const updateFields = [...formBuilder.fields];
			updateFields.splice(dragIndex, 1);
			updateFields.splice(hoverIndex, 0, draggedField);

			setFormBuilder(prevState => ({
				...prevState,
				fields: updateFields,
			}));
		}
	};

	const updateFieldSettings = id => {
		const field = formBuilder.fields.find(x => x.id === id);
		setSettingsForm(
			formBuilderCustomFormData({
				id: id,
				type: field.type,
				...field.settings,
			}),
		);
		setShowSettingsModal(true);
	};

	const handleUpdateFieldSettings = ({ id, ...settings }) => {
		setFormBuilder(prevState => ({
			...prevState,
			fields: prevState.fields.map(x => {
				if (id !== x.id) return x;

				return {
					...x,
					settings: {
						...settings,
						...(settings.options
							? {
									options: settings.options.map(x => ({
										value: x,
										label: x,
									})),
								}
							: {}),
					},
				};
			}),
		}));
		setSettingsForm([]);
	};

	return (
		<>
			<Row className='form-builder'>
				<Col md={3}>
					<div className='form-builder__available'>
						<h2>Velden</h2>
						<Field type={INPUT_TYPES.TEXT} addField={addField} />
						<Field type={INPUT_TYPES.NUMBER} addField={addField} />
						<Field type={INPUT_TYPES.SELECT} addField={addField} />
						<Field
							type={INPUT_TYPES.CHECKBOX}
							addField={addField}
						/>
						{/*<Field type={INPUT_TYPES.TOGGLE} addField={addField} />*/}
						<Field type={INPUT_TYPES.RADIO} addField={addField} />
						<Field
							type={INPUT_TYPES.TEXTAREA}
							addField={addField}
						/>
					</div>

					<div className='form-builder__available'>
						<h2>Formulier</h2>
						<DefaultForm type='barbeque' addForm={addForm} />
						<DefaultForm type='personeelsfeest' addForm={addForm} />
						<DefaultForm
							type='food-teambuilding'
							addForm={addForm}
						/>
						<DefaultForm type='leeg' addForm={addForm} />
					</div>

					<div className='form-builder__available'>
						<h2>Instellingen</h2>
						<p>
							<b>Naam:</b> {formBuilder.formName}
							<br />
							<b>Formaat:</b>{' '}
							{formBuilder.formSize === 'small'
								? 'Klein'
								: formBuilder.formSize === 'medium'
									? 'Medium'
									: 'Groot'}
						</p>
						<Button
							buttonStyle='secondary'
							label='Wijzigen'
							onClick={() => setShowModal(true)}
							block
						/>
					</div>
				</Col>
				<Col md={9}>
					<div className='form-builder__preview' ref={drop}>
						<div className='form-builder__preview-container'>
							<div>
								{formBuilder.fields.length === 0 ? (
									<p>Sleep velden hier om ze toe te voegen</p>
								) : null}
								{formBuilder.fields.map((field, index) => (
									<FormField
										key={field.id}
										index={index}
										id={field.id}
										type={field.type}
										settings={field.settings}
										formLength={formBuilder.fields.length}
										moveField={moveField}
										removeField={removeField}
										updateField={updateFieldSettings}
										isSelected={
											!!settingsForm.find(
												x =>
													x.name === 'id' &&
													x.value === field.id,
											)
										}
									/>
								))}
							</div>
						</div>
					</div>

					<div
						className='form-builder__preview-remove'
						ref={removeDrop}>
						Sleep velden hier om ze te verwijderen
					</div>
				</Col>
			</Row>
			<div className='btn__group' style={{ marginTop: '1.5rem' }}>
				<Button
					buttonStyle='primary'
					onClick={() => {
						save(formBuilder);
						close();
					}}
					label='Bewaar'
				/>
				<Button
					buttonStyle='secondary'
					onClick={close}
					label='Annuleer'
				/>
			</div>

			<Form
				submitOnClick={handleUpdateFieldSettings}
				fields={settingsForm}
				cancelOnClick={() => setSettingsForm([])}
				showModal={showSettingsModal}
				setShowModal={setShowSettingsModal}
				isModal
				addCancel
			/>

			<Form
				fields={formBuilderFormData(formBuilder)}
				submitOnClick={value => {
					setFormBuilder(prevState => ({
						...prevState,
						formName: value.formName,
						formSize: value.formSize,
					}));
				}}
				showModal={showModal}
				setShowModal={setShowModal}
				isModal
				addCancel
			/>
		</>
	);
};

export default FormBuilder;
