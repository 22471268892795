import React from 'react';
import { NavLink } from 'react-router-dom';

import './AdminHeader.scss';

export const AdminHeader = ({ title, optional = null, filter = null }) => {
	return (
		<>
			<section className='admin-header'>
				<h1>{title}</h1>
				{optional ? (
					<section className='actions'>
						<NavLink to={optional.url} className='btn btn--primary'>
							{optional.label}
						</NavLink>
					</section>
				) : null}
			</section>
			{filter ? (
				<section className='filter-container'>{filter}</section>
			) : null}
		</>
	);
};
