import { useEffect, useState } from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import {
	array,
	bool,
	func,
	number,
	object,
	oneOfType,
	string,
} from 'prop-types';

import { UiInput } from 'components/Form';

import './Select.scss';

const SelectField = ({
	id,
	name,
	label,
	placeholder,
	description,
	value,
	required,
	focused,
	setFocused,
	touched,
	setTouched,
	readOnly,
	disabled,
	errors,
	onChange,
	className,
	options,
	isClearable,
	isCreatable,
	isMulti,
}) => {
	const SelectComponent = isCreatable ? Creatable : Select;

	const [opt, setOpt] = useState(options);

	useEffect(() => {
		if (opt.length === 0 && options.length) setOpt(options);
	}, [options, opt]);

	return (
		<UiInput
			id={id}
			label={label}
			description={description}
			required={required}
			focused={focused}
			disabled={disabled || readOnly}
			className={className}
			hasValue={value.length > 0}
			hasError={errors.length > 0 && touched ? true : false}
			errors={errors}>
			<SelectComponent
				className='select'
				id={id}
				name={name}
				classNamePrefix='select'
				options={opt}
				isMulti={isMulti}
				closeMenuOnSelect={!isMulti}
				isClearable={isClearable}
				value={
					isMulti
						? opt.filter(option => value.includes(option.value))
						: opt.find(option => option.value === value)
				}
				onChange={(option, actionMeta) => {
					if (actionMeta.action === 'clear') {
						onChange('');
						return;
					}

					if (isMulti) {
						onChange(
							option.map(option => {
								if (option.__isNew__) {
									setOpt([
										...opt,
										{
											label: option.label,
											value: option.value,
										},
									]);
								}

								return option.value;
							}),
						);
					} else {
						if (option.__isNew__) {
							setOpt([
								...opt,
								{ label: option.label, value: option.value },
							]);
						}

						onChange(option.value);
					}
				}}
				onBlur={() => {
					if (setTouched) setTouched(true);
					if (setFocused) setFocused(false);
				}}
				onFocus={() => {
					if (setFocused) setFocused(true);
				}}
				placeholder={placeholder}
				isDisabled={disabled}
				styles={{
					menuPortal: base => ({
						...base,
						zIndex: 9999999,
						marginLeft: '-0.5rem',
					}),
				}}
				menuPortalTarget={document.getElementById('ketchup-base')}
				menuPosition='absolute'
			/>
		</UiInput>
	);
};

SelectField.defaultProps = {
	label: '',
	placeholder: '',
	description: '',
	required: false,
	disabled: false,
	validations: [],
	errors: [],
	options: [],
	isMulti: false,
	isClearable: true,
	isCreatable: false,
	isFixed: false,
	returnOptionsObj: false,
	value: '',
};

SelectField.propTypes = {
	name: string.isRequired,
	label: string,
	placeholder: string,
	description: string,
	value: oneOfType([string, number, array, object]),
	required: bool,
	disabled: bool,
	validations: array,
	errors: array,
	onChange: func.isRequired,
	options: array,
	isMulti: bool,
	isClearable: bool,
	isCreatable: bool,
	isFixed: bool,
	returnOptionsObj: bool,
};

export default SelectField;
