import { useDrag } from 'react-dnd';
import { getFieldDetail } from 'core/helpers/form-builder/form-builder.helper';

import Icon from 'components/Icon/Icon';

const Field = ({ type, addField }) => {
	const field = getFieldDetail(type);

	const [{ isDragging }, drag] = useDrag(() => ({
		type: 'FIELD',
		item: { type, id: 0 },
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	}));

	return (
		<div
			ref={drag}
			onClick={() => addField(type)}
			className={`form-builder__available-field${isDragging ? ' dragging' : ''}`}>
			<Icon icon={type} />
			{field.name}
		</div>
	);
};

export default Field;
