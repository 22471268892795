import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

import { arraySum } from '../../helpers';

let defaultState = {
	statics: {
		next: null,
		events: null,
		trainings: null,
		news: null,
		instructors: null,
		tags: null,
		endpointVisitors: null,
		invitations: null,
		stickers: null,
	},
	events: {
		all: [],
		unique: [],
	},
	trainings: {
		all: [],
		unique: [],
	},
	news: {
		all: [],
		unique: [],
	},
	mostCount: 0,
	mostEntryCount: 0,
	mostSubFeedCount: 0,
	staticsLoading: false,
	userStaticsLoading: false,
	userStatics: [],
};

const loadStatics = (state, { staticsLoading }) => ({
	...state,
	staticsLoading,
});

const loadStaticsCommit = (state, payload) => ({
	...state,
	staticsLoading: false,
	statics: payload,
	mostCount: arraySum(payload.stickers?.mostUsedStickers ?? [], 'count'),
	mostEntryCount: arraySum(
		payload.stickers?.mostEntryStickers ?? [],
		'count',
	),
	mostSubFeedCount: arraySum(
		payload.stickers?.mostSubFeedStickers ?? [],
		'count',
	),
});

const loadUserStatics = (state, { userStaticsLoading }) => ({
	...state,
	userStaticsLoading,
});

const loadUserStaticsCommit = (state, { data }) => ({
	...state,
	userStaticsLoading: false,
	userStatics: data,
});

const loadStaticDetails = (state, { type, isUnique, data }) => ({
	...state,
	[type]: {
		...state[type],
		[isUnique ? 'unique' : 'all']: data.data,
	},
});

export const staticsReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.LOAD_STATICS:
			return loadStatics(state, payload);
		case ACTION_TYPES.LOAD_STATICS_COMMIT:
			return loadStaticsCommit(state, payload.data);
		case ACTION_TYPES.LOAD_USER_STATICS:
			return loadUserStatics(state, payload);
		case ACTION_TYPES.LOAD_USER_STATICS_COMMIT:
			return loadUserStaticsCommit(state, payload.data);
		case ACTION_TYPES.LOAD_STATIC_DETAILS_COMMIT:
			return loadStaticDetails(state, payload);
		default:
			return state;
	}
};
