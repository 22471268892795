import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loadRegistrations } from 'core/store/entities/entity.actions';
import { loadToppings } from 'core/store/toppings/topping.actions';

import Loading from 'components/Loading/Loading';
import { Pill, Pills } from 'components/Pills';

const EntityRegistrations = prop => {
	const { id } = useParams();

	const dispatch = useDispatch();

	const { toppings, toppingsLoading } = useSelector(
		state => state.toppingReducer,
	);
	const { entitiesLoading, registrations } = useSelector(
		state => state.entityReducer,
	);

	useEffect(() => {
		dispatch(loadToppings());
		dispatch(loadRegistrations(prop.entity, id));
	}, [dispatch]);

	const breadSize = size => {
		switch (size) {
			case 'large_white':
				return 'Groot wit broodje';
			case 'large_dark':
				return 'Groot bruin broodje';
			case 'small_white':
				return 'Klein wit broodje';
			case 'small_dark':
			default:
				return 'Klein bruin broodje';
		}
	};

	const renderTrainingsDetail = ({ submissions: { order, remark } }) => {
		return (
			<>
				{order.map((x, i) => {
					let topping = toppings.find(t => t.id === x.topping);

					return (
						<div key={i}>
							<b>{x.date} : </b>
							{`${topping ? breadSize(x.size) : 'Geen broodje'} ${topping && topping.topping ? ` - ${topping.topping}` : ''} ${topping && topping.hasOptions && x.extra ? ' - smos' : ''}`}
						</div>
					);
				})}

				<div>
					<b>Opmerking : </b>
					{remark ? remark : 'Geen opmerkingen'}
				</div>
			</>
		);
	};

	const renderFormDetail = ({ submissions }) => {
		return submissions?.length ? (
			submissions.map((y, a) => {
				return (
					<div key={a}>
						<b>{y.field.label} : </b>
						{y.field.type === 'checkbox' ? (
							y.value === '1' ? (
								'ja'
							) : (
								'nee'
							)
						) : y.value ? (
							y.field.multiple ? (
								y.value.map((z, i) => {
									return (
										<span key={i}>
											{z}
											{i + 1 !== y.value.length
												? ', '
												: ''}
										</span>
									);
								})
							) : (
								<span
									dangerouslySetInnerHTML={{
										__html: y.value.replaceAll(
											'\n',
											'<br/>',
										),
									}}
								/>
							)
						) : (
							'niet opgegeven'
						)}
						<br />
					</div>
				);
			})
		) : (
			<div>Geen formulier ingegeven</div>
		);
	};

	return entitiesLoading || toppingsLoading ? (
		<Loading large centered />
	) : (
		<div>
			{registrations.length
				? registrations.map(x => {
						return (
							<div key={x.user.id}>
								<h3
									style={{
										marginBottom: '.5rem',
										marginTop: '2rem',
									}}>
									<Pills inline>
										<Pill
											type={
												x.user.confirmed === null
													? 'warning'
													: x.user.confirmed
														? 'success'
														: 'danger'
											}
											text={
												x.user.confirmed === null
													? 'ONBEVESTIGD'
													: x.user.confirmed
														? 'AANWEZIG'
														: 'AFWEZIG'
											}
										/>
									</Pills>
									{x.user.firstName} {x.user.lastName}
								</h3>
								{prop.entity === 'trainings'
									? renderTrainingsDetail(x)
									: renderFormDetail(x)}
							</div>
						);
					})
				: null}
		</div>
	);
};

export default EntityRegistrations;
