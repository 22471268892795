import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { array, bool, func, shape, string } from 'prop-types';

import { UiInput } from 'components/Form';

import TimePicker from './TimePicker/TimePicker';

import './RangePicker.scss';

const RangePicker = ({
	id,
	label,
	description,
	value,
	required,
	focused,
	setFocused,
	touched,
	setTouched,
	readOnly,
	disabled,
	errors,
	onChange,
	className,
}) => {
	const handleChange = (t, v) => {
		onChange({
			...value,
			[t]: v,
		});
	};

	return (
		<UiInput
			id={id}
			label={label}
			description={description}
			required={required}
			focused={focused}
			disabled={disabled || readOnly}
			className={className}
			hasValue={!!(value.from || value.until)}
			hasError={errors.length > 0 && touched ? true : false}
			errors={errors}>
			<div className='form-field__control__icon'>
				<FontAwesomeIcon icon={faClock} size='lg' fixedWidth />
			</div>

			<div className='range-picker'>
				<div className='hour'>
					<TimePicker
						disabled={disabled}
						value={value.from}
						handleChange={value => handleChange('from', value)}
						onBlur={() => {
							setTouched(true);
							setFocused(false);
						}}
						onFocus={() => setFocused(true)}
					/>
				</div>
				<span className='separator'>tot</span>
				<div className='hour'>
					<TimePicker
						value={value.until}
						disabled={disabled}
						handleChange={value => handleChange('until', value)}
						onBlur={() => {
							setTouched(true);
							setFocused(false);
						}}
						onFocus={() => setFocused(true)}
					/>
				</div>
			</div>
		</UiInput>
	);
};

RangePicker.defaultProps = {
	type: 'rangepicker',
	label: '',
	description: '',
	required: false,
	focused: false,
	touched: false,
	readOnly: false,
	disabled: false,
	validations: [],
	errors: [],
	value: { from: '', until: '' },
};

RangePicker.propTypes = {
	id: string,
	type: string,
	label: string,
	description: string,
	value: shape({ from: string, until: string }),
	className: string,
	required: bool,
	focused: bool,
	setFocused: func,
	touched: bool,
	setTouched: func,
	readOnly: bool,
	disabled: bool,
	validations: array,
	errors: array,
	onChange: func.isRequired,
};

export default RangePicker;
