import React from 'react';
import { useSelector } from 'react-redux';
import {
	getEntityFilter,
	getEntityTypeDetails,
} from 'core/helpers/entities/entity.helper';

import { EntityList } from 'components/Entity';

const EntityOverview = ({ entity }) => {
	const { permissions } = useSelector(state => state.authReducer);

	const entityType = getEntityTypeDetails(entity);

	return (
		<EntityList
			entity={entityType}
			filterOptions={getEntityFilter(entityType.type, permissions)}
			permissions={permissions}
		/>
	);
};

export default EntityOverview;
