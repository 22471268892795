import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

let defaultState = {
	instructors: [],
	instructorsLoading: false,
};

const loadInstructors = (state, { instructorsLoading }) => ({
	...state,
	instructorsLoading,
});

const loadInstructorsCommit = (state, payload) => ({
	...state,
	instructorsLoading: false,
	instructors: payload,
});

const handleInstructor = (state, { id, data }) => {
	if (id) {
		return {
			...state,
			instructors: state.instructors.map(x => {
				if (x.id !== id) return x;

				return {
					...x,
					...data.body,
				};
			}),
		};
	} else {
		return {
			...state,
			instructors: [...state.instructors, data.body],
		};
	}
};

const removeInstructor = (state, { id }) => ({
	...state,
	instructors: state.instructors.filter(x => x.id !== id),
});

export const instructorReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.INSTRUCTORS_LOAD:
			return loadInstructors(state, payload);
		case ACTION_TYPES.INSTRUCTORS_LOAD_COMMIT:
			return loadInstructorsCommit(state, payload.data);
		case ACTION_TYPES.INSTRUCTORS_POST_COMMIT:
			return handleInstructor(state, payload);
		case ACTION_TYPES.INSTRUCTORS_DELETE_COMMIT:
			return removeInstructor(state, payload);
		default:
			return state;
	}
};
