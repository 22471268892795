export const movingMissionColors = {
	baseball: 'rgba(255, 99, 71, .75)',
	basketbal: 'rgba(255, 69, 0, .75)',
	boksen: 'rgba(140, 82, 255, .75)',
	dans: 'rgba(255, 215, 0, .75)',
	fietsen: 'rgba(173, 255, 47, .75)',
	fitness: 'rgba(255, 105, 180, .75)',
	golf: 'rgba(75, 0, 130, .75)',
	lopen: 'rgba(124, 252, 0, .75)',
	padel: 'rgba(219, 112, 147, .75)',
	schaatsen: 'rgba(255, 160, 122, .75)',
	skiën: 'rgba(32, 178, 170, .75)',
	snowboarden: 'rgba(135, 206, 250, .75)',
	tennis: 'rgba(119, 136, 153, .75)',
	voetbal: 'rgba(28, 163, 30, .75)',
	volleybal: 'rgba(255, 0, 255, .75)',
	wandelen: 'rgba(255, 87, 87, .75)',
	yoga: 'rgba(128, 0, 0, .75)',
	zwemmen: 'rgba(0, 128, 128, .75)',
};

export const movingMissionIcon = (types, type) =>
	types.find(x => x.name === type);
