import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';
import { db } from 'dexie-db';

import { store } from '../store.config';

export const logoutUser = () => {
	dispatchAction({
		type: ACTION_TYPES.AUTH_LOGOUT,
		payload: {},
		meta: {
			action: {
				effect: {
					url: '/logout',
					method: 'get',
					retry: false,
				},
				commit: { type: ACTION_TYPES.AUTH_LOGOUT_COMMIT },
			},
			followup: [clearStorage],
		},
	});
};

export const loadProfile = () => {
	const state = store.getState();
	const { meInfoFetched, loginTries } = state.authReducer;
	if (!meInfoFetched && loginTries < 5) {
		dispatchAction({
			type: ACTION_TYPES.AUTH_LOAD_PROFILE,
			payload: { authLoading: true, loginTries: loginTries + 1 },
			meta: {
				action: {
					effect: {
						url: `/auth/me`,
						method: 'get',
					},
					commit: { type: ACTION_TYPES.AUTH_LOAD_PROFILE_COMMIT },
					rollback: {
						type: ACTION_TYPES.AUTH_LOGOUT_COMMIT,
						followup: [clearStorage],
					},
				},
				followup: [loadUserSettings],
			},
		});
	}
};

const loadUserSettings = store => {
	//TODO: set listener for changes in auth user (role, permissions, picture, contacts + pictures, settings)
	const { settings } = store.authReducer;

	const darkThemeMediaQuery = window.matchMedia(
		'(prefers-color-scheme: dark)',
	);
	const mode = settings?.theme ?? 'light';

	dispatchAction({
		type: ACTION_TYPES.THEME_MODE_UPDATE,
		payload: { data: { theme: mode } },
	});

	dispatchAction({
		type: ACTION_TYPES.THEME_UPDATE,
		payload: {
			data: {
				theme:
					mode === 'auto'
						? darkThemeMediaQuery.matches
							? 'dark'
							: 'light'
						: mode,
			},
		},
	});
};

export const changeSidebar = () => {
	const state = store.getState();
	const {
		settings: { adminSidebarOpen },
	} = state.authReducer;

	dispatchAction({
		type: ACTION_TYPES.AUTH_UPDATE_SETTINGS,
		payload: {},
		meta: {
			action: {
				effect: {
					url: `/auth/me/settings`,
					method: 'put',
					body: { settings: { adminSidebarOpen: !adminSidebarOpen } },
				},
				commit: { type: ACTION_TYPES.AUTH_UPDATE_SETTINGS_COMMIT },
				rollback: {},
			},
			followup: [],
		},
	});
};

const clearStorage = () => {
	localStorage.clear();
	db.delete();
};
