import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loadMovingMission } from 'core/store/moving-mission/moving-mission.actions';

import Loading from 'components/Loading/Loading';
import { MovingMissionForm } from 'components/MovingMission';

const MovingMissionDetail = () => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const { movingMissionLoading, movingMission } = useSelector(
		state => state.movingMissionReducer,
	);

	useEffect(() => {
		if (id) {
			dispatch(loadMovingMission(id));
		}
	}, [dispatch]);

	return movingMissionLoading || (id && !movingMission) ? (
		<Loading large centered />
	) : (
		<MovingMissionForm object={movingMission} />
	);
};

export default MovingMissionDetail;
