import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { getEntityTypeDetails } from 'core/helpers/entities/entity.helper';
import { dispatchAction } from 'core/services/actions';

export const loadStatics = () => () => {
	dispatchAction({
		type: ACTION_TYPES.LOAD_STATICS,
		payload: { staticsLoading: true },
		meta: {
			action: {
				effect: {
					url: `/admin/statistics`,
					method: 'get',
				},
				commit: { type: ACTION_TYPES.LOAD_STATICS_COMMIT },
			},
			followup: [],
		},
	});
};

export const loadStaticDetails = (type, isUnique) => () => {
	dispatchAction({
		type: ACTION_TYPES.LOAD_STATIC_DETAILS,
		payload: {},
		meta: {
			action: {
				effect: {
					url: `/admin/statistics/${getEntityTypeDetails(type).type}?unique=${isUnique ? 'true' : 'false'}`,
					method: 'get',
				},
				commit: {
					type: ACTION_TYPES.LOAD_STATIC_DETAILS_COMMIT,
					payload: {
						type: getEntityTypeDetails(type).type,
						isUnique: isUnique ?? false,
					},
				},
			},
			followup: [],
		},
	});
};

export const loadUserStatic = id => () => {
	dispatchAction({
		type: ACTION_TYPES.LOAD_USER_STATICS,
		payload: { userStaticsLoading: true },
		meta: {
			action: {
				effect: {
					url: `/admin/statistics/user/${id}`,
					method: 'get',
				},
				commit: {
					type: ACTION_TYPES.LOAD_USER_STATICS_COMMIT,
					payload: {},
				},
			},
			followup: [],
		},
	});
};
