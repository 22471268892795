import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';
import { successMessageService } from 'core/services/message';

export const loadEmployees = () => () => {
	dispatchAction({
		type: ACTION_TYPES.EMPLOYEES_LOAD,
		payload: { employeesLoading: true },
		meta: {
			action: {
				effect: {
					url: '/users',
					method: 'get',
				},
				commit: { type: ACTION_TYPES.EMPLOYEES_LOAD_COMMIT },
			},
			followup: [],
		},
	});
};

export const updateRoles = data => () => {
	dispatchAction({
		type: ACTION_TYPES.ROLES_PUT,
		payload: {},
		meta: {
			action: {
				effect: {
					url: '/admin/roles',
					method: 'put',
					body: data,
				},
				commit: {
					type: ACTION_TYPES.ROLES_PUT_COMMIT,
					payload: { roles: data },
				},
			},
			toast: {
				type: 'success',
				text: successMessageService('role', 'updated'),
			},
			followup: [],
		},
	});
};
