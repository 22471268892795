import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMovingMissionFilter } from 'core/helpers/moving-mission/moving-mission.service';
import { isPast, toShortDate } from 'core/services/date';
import {
	deleteMovingMission,
	loadMovingMissions,
} from 'core/store/moving-mission/moving-mission.actions';

import { Confirmation } from 'components/Confirmation/Confirmation';
import { AdminHeader } from 'components/Layouts/AdminLayout/AdminHeader/AdminHeader';
import { ListItem } from 'components/ListItem/ListItem';
import Loading from 'components/Loading/Loading';

const MovingMissionList = () => {
	const dispatch = useDispatch();
	const filterOptions = getMovingMissionFilter();

	const { permissions } = useSelector(state => state.authReducer);
	const { movingMissionLoading, movingMissions } = useSelector(
		state => state.movingMissionReducer,
	);

	const [filter, setFilter] = useState(filterOptions[0]);
	const [filteredList, setFilteredList] = useState([]);
	const [modal, setModal] = useState({ action: null, id: null });

	useEffect(() => {
		dispatch(loadMovingMissions());
	}, [dispatch]);

	useEffect(() => {
		handleFilter(filter);
	}, [movingMissions]);

	const handleFilter = filter => {
		setFilter(filter);
		setFilteredList(
			movingMissions.filter(x =>
				filter.name === 'all' ? !isPast(x.endDate) : isPast(x.endDate),
			),
		);
	};

	const handleClick = id => {
		dispatch(deleteMovingMission(id));
	};

	return (
		<section>
			<AdminHeader
				title='Moving Mission'
				optional={
					permissions.includes(`moving_mission_add`)
						? {
								url: 'toevoegen',
								label: `+ Toevoegen`,
							}
						: null
				}
				filter={
					<>
						{filterOptions
							.filter(x => x.name !== 'all')
							.map(({ name, label }, i) => (
								<button
									key={i}
									type='button'
									onClick={() => {
										handleFilter(
											filter.name === name
												? { name: 'all' }
												: { name, label },
										);
									}}
									className={`btn btn--small btn--${
										filter.name === name
											? `primary`
											: `secondary`
									}`}>
									{label}
								</button>
							))}
					</>
				}
			/>

			{!movingMissionLoading ? (
				filteredList.length ? (
					filteredList.map(mission => (
						<ListItem
							key={mission.id}
							id={mission.id}
							title={toShortDate(mission.startDate)}
							slug={
								permissions.includes(`moving_mission_update`)
									? `/moving-mission/${mission.id}`
									: null
							}
							showDeleteIcon={
								permissions.includes(`moving_mission_delete`)
									? !isPast(mission.endDate)
									: null
							}
							publishedAt={mission.startDate}
							setModal={setModal}
							showResultsIcon={
								!!permissions.includes(
									`moving_mission_see_results`,
								)
							}>
							<i>
								<b>Actief tussen: </b>
								{toShortDate(mission.startDate)} en{' '}
								{toShortDate(mission.endDate)}
							</i>
						</ListItem>
					))
				) : (
					<div className='empty-list'>
						{filter.name === 'all'
							? 'Geen data om weer te geven'
							: 'Er zijn geen items die voldoen aan je selectie'}
					</div>
				)
			) : (
				<Loading />
			)}

			<Confirmation
				show={!!modal.action}
				close={() => setModal({ action: null, id: null })}
				action={() => handleClick(modal.id)}
				title='Weet je het zeker?'>
				Weet je zeker dat je dit item wil verwijderen?
			</Confirmation>
		</section>
	);
};

export default MovingMissionList;
