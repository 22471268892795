import { downloadFile } from './download.service';

const getFileSize = size => {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

	if (size === 0) return '0 Byte';

	const i = parseInt(Math.floor(Math.log(size) / Math.log(1024)));

	return `${Math.round(size / Math.pow(1024, i), 2)} ${sizes[i]}`;
};

export { getFileSize, downloadFile };
