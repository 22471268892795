import React, { useState } from 'react';
import {
	faGlobeEurope,
	faTimes,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { entityLinkData } from 'core/helpers/entities/entry.form-data';
import { useForm } from 'core/hooks/form/useForm';

import { Button, InputField } from 'components/Form';
import Modal from 'components/Modal/Modal';

const AddEntityLink = ({ value, setValue, label }) => {
	const [form] = useState(entityLinkData());
	const [showModal, setShowModal] = useState(false);

	const { register, isValid, getValues, reset } = useForm();

	const saveLink = () => {
		setValue([...value, getValues()]);
		setShowModal(false);
		reset();
	};

	const handleRemove = index => {
		const links = value;
		links.splice(index, 1);
		setValue(links);
	};

	const handleRemoveAll = () => {
		setValue([]);
	};

	return (
		<>
			<Button
				buttonStyle='primary'
				label={label}
				onClick={() => setShowModal(!showModal)}
				block
			/>
			{value.length !== 0 ? (
				<div className='form-field__upload-file-section'>
					{value.map((link, index) => (
						<div className='form-field__upload-file' key={index}>
							<div className='form-field__upload-file-label'>
								<FontAwesomeIcon
									icon={faGlobeEurope}
									fixedWidth
								/>
								<span>{link.name || link.url}</span>
							</div>
							<div className='form-field__upload-file__buttons'>
								<button
									className='form-field__upload-file-remove-button'
									onClick={() => handleRemove(index)}>
									<FontAwesomeIcon
										icon={faTimes}
										fixedWidth
									/>
								</button>
							</div>
						</div>
					))}
					<div className='form-field__upload-buttons'>
						<button
							className='form-field__upload-remove-all-button'
							onClick={handleRemoveAll}>
							<FontAwesomeIcon icon={faTrash} fixedWidth />
							<span>Verwijder alles</span>
						</button>
					</div>
				</div>
			) : null}
			<Modal
				show={showModal}
				close={() => {
					setShowModal(false);
					reset();
				}}
				title='Geef de waarden in'
				actions={[
					{
						action: saveLink,
						label: 'Bewaren',
						disabled: !isValid(),
					},
				]}
				small>
				<InputField {...register('name', form.name)} />
				<InputField {...register('url', form.url)} />
			</Modal>
		</>
	);
};

export default AddEntityLink;
