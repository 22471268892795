import { createContext, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { node } from 'prop-types';

const SubmitContext = createContext({
	handleSubmit: () => Promise.resolve(),
	submitting: false,
});

export const SubmitProvider = ({ children }) => {
	const dispatch = useDispatch();
	const [submitting, setSubmitting] = useState(false);

	const handleSubmit = async submitFunction => {
		if (submitting) return;

		setSubmitting(() => true);
		try {
			await dispatch(submitFunction);
		} finally {
			setSubmitting(() => false);
		}
	};

	return (
		<SubmitContext.Provider value={{ handleSubmit, submitting }}>
			{children}
		</SubmitContext.Provider>
	);
};

SubmitProvider.propTypes = {
	children: node,
};

export const useSubmit = () => {
	return useContext(SubmitContext);
};
