import React, { forwardRef } from 'react';
import { bool, node } from 'prop-types';

const CardBody = forwardRef(({ isFolded, children }, ref) => {
	return (
		<div
			className={`card__body ${isFolded ? 'folded' : 'unfolded'}`}
			ref={ref}>
			{children}
		</div>
	);
});

CardBody.propTypes = {
	isFolded: bool.isRequired,
	children: node,
};

export default CardBody;
