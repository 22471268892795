import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';

export const loadToppings = () => () => {
	dispatchAction({
		type: ACTION_TYPES.TOPPINGS_LOAD,
		payload: { toppingsLoading: true },
		meta: {
			action: {
				effect: {
					url: '/toppings',
					method: 'get',
				},
				commit: { type: ACTION_TYPES.TOPPINGS_LOAD_COMMIT },
			},
			followup: [],
		},
	});
};
