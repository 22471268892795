import { INPUT_TYPES } from 'core/constants';
import { getDate } from 'core/services/date';
import { isRequired } from 'core/services/form';

export const movingMissionFormData = object => {
	return {
		layout: {
			type: 'row',
			name: 'row',
			fields: ['col1', 'col2'],
		},
		form: [
			{
				type: 'col',
				name: 'col1',
				breakpoint: { sm: 12, md: 7, lg: 8 },
				order: { smOrder: 2, mdOrder: 1, lgOrder: 1 },
				fields: [
					'streak_interval',
					'streak_bonus',
					'streak_freeze',
					'selfies',
					'nature_picture',
				],
			},
			{
				type: 'col',
				name: 'col2',
				breakpoint: { sm: 12, md: 5, lg: 4 },
				order: { smOrder: 1, mdOrder: 2, lgOrder: 2 },
				fields: ['start_date', 'end_date', 'cooldown_period'],
			},
			{
				type: INPUT_TYPES.DATEINPUT,
				name: 'start_date',
				label: 'Startdatum',
				description:
					'Selecteer de datum vanaf wanneer de missie actief moet worden',
				value: object?.startDate ? getDate(object?.startDate) : '',
				required: true,
				validations: [isRequired],
				disabledDays: [{ before: new Date() }],
			},
			{
				type: INPUT_TYPES.DATEINPUT,
				name: 'end_date',
				label: 'einddatum',
				description:
					'Selecteer de datum tot wanneer de missie actief moet blijven',
				value: object?.endDate ? getDate(object?.endDate) : '',
				required: true,
				validations: [isRequired],
				disabledDays: [{ before: new Date() }],
			},
			{
				type: INPUT_TYPES.NUMBER,
				name: 'cooldown_period',
				label: 'Cooldown',
				description:
					'De missie zal nog dit aantal dagen zichtbaar zijn in read-only',
				validations: [isRequired],
				required: true,
				value: object?.cooldownPeriod ?? 14,
			},
			{
				type: INPUT_TYPES.NUMBER,
				name: 'streak_interval',
				label: 'Hoe lang duurt een streak?',
				validations: [isRequired],
				required: true,
				value: object?.streakInterval ?? 7,
			},
			{
				type: INPUT_TYPES.NUMBER,
				name: 'streak_bonus',
				label: 'Wat is de bonus voor een hele Streak bewegen?',
				validations: [isRequired],
				required: true,
				value: object?.streakBonus ?? 5,
			},
			{
				type: INPUT_TYPES.NUMBER,
				name: 'streak_freeze',
				label: 'Wat is de kostprijs van een Streak Freeze',
				description:
					'De laatst ontvangen bonus is de standaard kostprijs. Dit bedrag wordt extra aangerekend bovenop de standaard prijs.',
				validations: [],
				required: true,
				value: object?.streakFreeze ?? 0,
			},
			{
				type: INPUT_TYPES.NUMBER,
				name: 'selfies',
				label: 'Aantal extra punten voor een selfie?',
				validations: [isRequired],
				required: true,
				value: object?.selfies ?? 5,
			},
			{
				type: INPUT_TYPES.NUMBER,
				name: 'nature_picture',
				label: 'Aantal extra punten voor een natuurfoto',
				validations: [isRequired],
				required: true,
				value: object?.naturePicture ?? 1,
			},
		],
	};
};
