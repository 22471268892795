import { useDrag, useDrop } from 'react-dnd';
import {
	faArrowDown,
	faArrowUp,
	faPen,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	getFieldDetail,
	getFieldInput,
} from 'core/helpers/form-builder/form-builder.helper';

import { Button } from 'components/Form';
import Icon from 'components/Icon/Icon';

const FormField = ({
	id,
	type,
	settings,
	index,
	formLength,
	moveField,
	removeField,
	updateField,
	isSelected,
}) => {
	const field = getFieldDetail(type);
	const input = getFieldInput(type, field, settings);

	const [, drop] = useDrop({
		accept: ['FIELD', 'FORM'],
		hover: item => {
			if (!item || item.index === index) return;

			moveField(item.index, index);
			item.index = index;
		},
	});

	const [{ isDragging }, drag] = useDrag({
		type: 'FIELD',
		item: { id, index },
		collect: monitor => ({ isDragging: monitor.isDragging() }),
		end: (item, monitor) => {
			if (!monitor.didDrop()) {
				removeField(item.id);
			}
		},
	});

	return (
		<div
			className={`form-builder__preview-container--item${isDragging ? ' dragging' : ''}`}
			ref={node => drag(drop(node))}>
			<div className='form-builder__preview-container--item__header'>
				<Icon icon={type} />
				{field.name}

				<div className='btn__group'>
					<Button
						buttonStyle='secondary'
						className='error'
						onClick={() => removeField(id)}
						buttonSize='small'>
						<FontAwesomeIcon icon={faTrash} fixedWidth />
					</Button>
					<Button
						buttonStyle='secondary'
						onClick={() => updateField(id)}
						buttonSize='small'>
						<FontAwesomeIcon icon={faPen} fixedWidth />
					</Button>
					<Button
						buttonStyle='secondary'
						onClick={() => moveField(index, index - 1)}
						buttonSize='small'
						disabled={index === 0}>
						<FontAwesomeIcon icon={faArrowUp} fixedWidth />
					</Button>
					<Button
						buttonStyle='secondary'
						onClick={() => moveField(index + 1, index)}
						buttonSize='small'
						disabled={index === formLength - 1}>
						<FontAwesomeIcon icon={faArrowDown} fixedWidth />
					</Button>
				</div>
			</div>

			{!isSelected ? input : null}
		</div>
	);
};

export default FormField;
