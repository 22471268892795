import { UiInput } from 'components/Form';

import './InputRadioButton.scss';

export const InputRadioButton = ({
	type,
	id,
	name,
	label,
	description,
	value,
	required,
	//focused,
	//setFocused,
	touched,
	setTouched,
	readOnly,
	disabled,
	errors,
	onChange,
	className,
	options,
	//validations,
	//validate,
}) => {
	return (
		<UiInput
			id={id}
			label={label}
			description={description}
			required={required}
			disabled={disabled || readOnly}
			className={className}
			hasValue={!!value}
			hasError={errors?.length > 0 && touched ? true : false}
			errors={errors}
			radio>
			{options
				.filter(option => !option.hide)
				.map(option => (
					<label
						htmlFor={`${id}-${option.value}`}
						key={option.value}
						className={
							value === option.value
								? 'form-field--has-value'
								: ''
						}>
						<input
							name={name}
							type={type}
							id={`${id}-${option.value}`}
							value={option.value}
							checked={value === option.value}
							onChange={() => {
								if (onChange) onChange(option.value);
								if (setTouched) setTouched(true);
							}}
							readOnly={readOnly}
							disabled={option.disabled || disabled}
						/>
						<span>{option.label}</span>
					</label>
				))}
		</UiInput>
	);
};

/*
InputRadioButton.defaultProps = {
	type: 'radio',
	label: '',
	description: '',
	required: false,
	readOnly: false,
	disabled: false,
	validations: [],
	errors: [],
};
InputRadioButton.propTypes = {
	type: string.isRequired,
	name: string.isRequired,
	label: string,
	description: string,
	defaultChecked: string,
	value: string,
	required: bool,
	readOnly: bool,
	options: arrayOf(
		shape({
			value: oneOfType([string, number]),
			label: string,
			disabled: bool,
		}),
	).isRequired,
	disabled: bool,
	validations: array,
	errors: array,
	onChange: func.isRequired,
	validate: func.isRequired,
	className: string,
};*/
export default InputRadioButton;
