import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';
import { successMessageService } from 'core/services/message';

import { store } from '../store.config';

export const changeMode = mode => {
	dispatchAction({
		type: ACTION_TYPES.THEME_MODE_UPDATE,
		payload: { data: { theme: mode } },
		meta: {
			action: {
				effect: {
					url: `/auth/me/settings`,
					method: 'put',
					body: { settings: { theme: mode } },
				},
				commit: { type: ACTION_TYPES.THEME_MODE_UPDATE },
				rollback: {},
			},
			followup: [changeTheme],
		},
	});
};

export const changeTheme = () => {
	const state = store.getState();
	const { mode } = state.applicationReducer;

	dispatchAction({
		type: ACTION_TYPES.THEME_UPDATE,
		payload: { data: { theme: mode } },
	});
};

export const loadCoces = () => () => {
	dispatchAction({
		type: ACTION_TYPES.COCES_LOAD,
		payload: { employeesLoading: true },
		meta: {
			action: {
				effect: {
					url: '/coces',
					method: 'get',
				},
				commit: { type: ACTION_TYPES.COCES_LOAD_COMMIT },
			},
			followup: [],
		},
	});
};

export const loadDepartments = () => () => {
	dispatchAction({
		type: ACTION_TYPES.DEPARTMENTS_LOAD,
		payload: { employeesLoading: true },
		meta: {
			action: {
				effect: {
					url: '/departments',
					method: 'get',
				},
				commit: { type: ACTION_TYPES.DEPARTMENTS_LOAD_COMMIT },
			},
			followup: [],
		},
	});
};

export const loadPermissions = () => () => {
	dispatchAction({
		type: ACTION_TYPES.PERMISSIONS_LOAD,
		payload: { permissionsLoading: true },
		meta: {
			action: {
				effect: {
					url: '/admin/permissions',
					method: 'get',
				},
				commit: { type: ACTION_TYPES.PERMISSIONS_LOAD_COMMIT },
			},
			followup: [],
		},
	});
};

export const loadRoles = () => () => {
	dispatchAction({
		type: ACTION_TYPES.ROLES_LOAD,
		payload: { rolesLoading: true },
		meta: {
			action: {
				effect: {
					url: '/admin/roles',
					method: 'get',
				},
				commit: { type: ACTION_TYPES.ROLES_LOAD_COMMIT },
			},
			followup: [],
		},
	});
};

export const updatePermissions = data => () => {
	dispatchAction({
		type: ACTION_TYPES.PERMISSIONS_PUT,
		payload: {},
		meta: {
			action: {
				effect: {
					url: '/admin/permissions',
					method: 'put',
					body: data,
				},
				commit: {
					type: ACTION_TYPES.PERMISSIONS_PUT_COMMIT,
					payload: { roles: data },
				},
			},
			toast: {
				type: 'success',
				text: successMessageService('permission', 'updated'),
			},
			followup: [],
		},
	});
};

export const sendNewsletter = () => () => {
	dispatchAction({
		meta: {
			action: {
				effect: {
					url: '/job/send-newsletter',
					method: 'get',
				},
			},
			toast: {
				type: 'success',
				text: successMessageService('newsletter', 'sent'),
			},
			followup: [],
		},
	});
};

export const loadSportTypes = () => () => {
	dispatchAction({
		type: ACTION_TYPES.SPORTS_LOAD,
		payload: { sportsLoading: true },
		meta: {
			action: {
				effect: {
					url: '/moving-mission/types',
					method: 'get',
				},
				commit: { type: ACTION_TYPES.SPORTS_LOAD_COMMIT },
			},
			followup: [],
		},
	});
};
