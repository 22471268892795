import React, { useEffect, useState } from 'react';
import {
	faEye,
	faPenToSquare,
	faPlus,
	faSortDown,
	faSortUp,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, InputField } from 'components/Form';
import { Col, Row } from 'components/Grid';
import { Pagination } from 'components/Pagination/Pagination';

const DataTable = ({ columns, data, add, remove, search, paginate, style }) => {
	const pageCount = Math.ceil((data?.length || 0) / 20);

	const [searchTag, setSearchTag] = useState('');
	const [sort, setSort] = useState([]);

	const [page, setPage] = useState(1);
	const [fromItem, setFromItem] = useState(1);
	const [toItem, setToItem] = useState(paginate ? 20 : 1);

	const [dataSet, setDataSet] = useState([]);
	const [sortedSet, setSortedSet] = useState([]);

	useEffect(() => {
		// Handle sorting and pagination together
		let sortedData = [...data];

		if (sort.length) {
			sortedData = sortedData.sort((a, b) => {
				for (let { name, direction } of sort) {
					const aValue = a[name] || '';
					const bValue = b[name] || '';

					if (aValue < bValue) return direction === 'asc' ? -1 : 1;
					if (aValue > bValue) return direction === 'asc' ? 1 : -1;
				}
				return 0;
			});
		}

		setSortedSet(sortedData);

		// Handle pagination
		if (paginate) paginated();
	}, [data, sort, page, pageCount]);

	const paginated = () => {
		let sortedData = [...sortedSet];

		const startIndex = (page - 1) * 20;
		const endIndex = startIndex + 20;
		setDataSet(sortedData.slice(startIndex, endIndex));
		setFromItem(startIndex + 1);
		setToItem(page === pageCount ? sortedData.length : endIndex);
	};

	useEffect(() => {
		if (searchTag !== '') {
			const lowerCaseQuery = searchTag.toLowerCase();
			const queryTerms = lowerCaseQuery.split(' ');

			const result = sortedSet.filter(item => {
				return queryTerms.every(term => {
					return Object.values(item).some(value =>
						value.toString().toLowerCase().includes(term),
					);
				});
			});

			setDataSet(result);
			setFromItem(1);
			setToItem(result.length);
		} else {
			setDataSet(sortedSet);
			if (paginate) paginated();
		}
	}, [searchTag, sortedSet, page]);

	const handlePagination = ({ selected }) => {
		if (!paginate || searchTag) return;
		setPage(selected + 1);
	};

	const handleSort = column => {
		setSort(prevState => {
			const index = prevState.findIndex(x => x.name === column.name);

			if (index === -1) {
				return [...prevState, { name: column.name, direction: 'asc' }];
			}

			const newState = [...prevState];
			if (newState[index].direction === 'asc') {
				newState[index].direction = 'desc';
			} else {
				newState.splice(index, 1);
			}

			return newState;
		});
	};

	return (
		<div>
			{search ? (
				<Row>
					<Col md={4} className='offset-md-8'>
						<InputField
							id='search'
							name='search'
							value={searchTag}
							placeholder='Zoeken'
							setFocused={() => ''}
							setTouched={() => ''}
							onChange={value => setSearchTag(value)}
							required
						/>
					</Col>
				</Row>
			) : null}

			<div className='table--wrapper'>
				<table style={style}>
					<thead>
						<tr>
							{columns.map(column => (
								<th
									key={column.name}
									className={`${column.className} ${column.sort ? 'sort' : ''}`}
									onClick={() => handleSort(column)}>
									{column.label}
									{column.sort ? (
										<div>
											<FontAwesomeIcon
												icon={faSortUp}
												className={
													sort.find(
														sort =>
															sort.name ===
															column.name,
													)?.direction === 'asc'
														? 'active'
														: 'disabled'
												}
											/>
											<FontAwesomeIcon
												icon={faSortDown}
												className={
													sort.find(
														sort =>
															sort.name ===
															column.name,
													)?.direction === 'desc'
														? 'active'
														: 'disabled'
												}
											/>
										</div>
									) : null}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{dataSet.length ? (
							dataSet.map((item, index) => (
								<tr key={index}>
									{columns.map(column => (
										<td
											key={`${column.name}-${index}`}
											className={column.className}>
											{column.name !== 'actions' ? (
												item[column.name]
											) : (
												<section className='list-item__icons btn__group'>
													{Object.keys(
														column.actions,
													).map(name => (
														<Button
															key={`${name}-${index}-button`}
															buttonStyle='secondary'
															buttonSize='small'
															className={
																name ===
																'remove'
																	? 'error'
																	: ''
															}
															onClick={() =>
																column.actions[
																	name
																](
																	index,
																	item?.id ??
																		0,
																)
															}
															ariaLabel={
																name ===
																'update'
																	? 'Wijzig item'
																	: name ===
																		  'remove'
																		? 'verwijder item'
																		: 'bekijk item'
															}>
															<FontAwesomeIcon
																icon={
																	name ===
																	'update'
																		? faPenToSquare
																		: name ===
																			  'remove'
																			? faTrash
																			: faEye
																}
																className='icon'
															/>
														</Button>
													))}
												</section>
											)}
										</td>
									))}
								</tr>
							))
						) : (
							<tr>
								<td colSpan={columns?.length ?? 0}>
									<div className='empty-list'>
										Nog geen data
									</div>
								</td>
							</tr>
						)}
					</tbody>
					{remove || add ? (
						<tfoot>
							<tr>
								<td colSpan={(columns?.length ?? 0) - 1}>
									{remove ? (
										<Button
											buttonStyle='primary'
											buttonSize='small'
											className='error'
											onClick={remove}
											disabled={data.length === 0}>
											<FontAwesomeIcon
												icon={faTrash}
												fixedWidth
											/>{' '}
											Verwijder alles
										</Button>
									) : null}
								</td>
								<td className='right'>
									{add ? (
										<Button
											buttonStyle='secondary'
											buttonSize='small'
											onClick={add}>
											<FontAwesomeIcon
												icon={faPlus}
												fixedWidth
											/>{' '}
											Toevoegen
										</Button>
									) : null}
								</td>
							</tr>
						</tfoot>
					) : null}
				</table>
			</div>

			{paginate && !searchTag ? (
				<Pagination
					from={fromItem}
					to={toItem}
					total={data?.length || 0}
					pageCount={pageCount}
					paginate={handlePagination}
				/>
			) : null}
		</div>
	);
};

export default DataTable;
