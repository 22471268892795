import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOnlineStatus } from 'core/hooks/OnlineStatusProvider';
import { bool, object, oneOfType, string } from 'prop-types';

const CardHeader = ({
	title,
	subTitle,
	url,
	meta,
	icon,
	loading,
	withReadMore,
	showArrow,
	isFolded,
	setFolded,
}) => {
	const online = useOnlineStatus();

	return (
		<div className='card__header'>
			<div className='card__header_wrapper'>
				{withReadMore && showArrow ? (
					<button
						className='card__header_read-more'
						onClick={setFolded}
						aria-label='lees meer'>
						<FontAwesomeIcon
							icon={isFolded ? faChevronDown : faChevronUp}
						/>
					</button>
				) : null}

				{meta ? (
					<h4>
						{loading ? (
							online ? (
								<Skeleton />
							) : (
								'Item kan niet worden geladen.'
							)
						) : (
							meta
						)}
					</h4>
				) : null}

				<h2>
					{icon ? <FontAwesomeIcon icon={icon} fixedWidth /> : null}
					{loading ? (
						online ? (
							<Skeleton />
						) : (
							'Item kan niet worden geladen'
						)
					) : url ? (
						<Link to={url}>{title}</Link>
					) : (
						title
					)}
				</h2>

				{subTitle ? (
					<h3>
						{loading ? (
							online ? (
								<Skeleton />
							) : (
								'Item kan niet worden geladen.'
							)
						) : (
							subTitle
						)}
					</h3>
				) : null}
			</div>
		</div>
	);
};

CardHeader.propTypes = {
	title: oneOfType([string, object]).isRequired,
	subTitle: string,
	url: string,
	meta: string,
	icon: object,
	withReadMore: bool,
	showArrow: bool,
	loading: bool.isRequired,
};

export default CardHeader;
