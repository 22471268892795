import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { useSubmit } from 'core/hooks/submit/useSubmit';
import { routesConfig } from 'core/routers/routesConfig';
import { bool } from 'prop-types';

import Navigation from 'components/Layouts/Navigation/Navigation';
import TopBar from 'components/Layouts/TopBar/TopBar';
import Toasts from 'components/Snackbar/Snackbar';

import './AdminLayout.scss';

const AdminLayout = () => {
	const { submitting } = useSubmit();

	const {
		permissions,
		settings: { adminSidebarOpen },
	} = useSelector(state => state.authReducer);

	const [isSidebarOpen, setIsSidebarOpen] = useState(
		adminSidebarOpen ?? true,
	);

	const toggleSidebar = status => {
		setIsSidebarOpen(status ?? adminSidebarOpen ?? true);
	};

	return (
		<div
			className={`admin ${isSidebarOpen ? 'sidebar-open' : ''}${submitting ? ' busy' : ''}`}>
			<TopBar toggleSidebar={toggleSidebar} permissions={permissions} />
			<Navigation isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

			<main className='admin-content'>
				<Routes>
					{routesConfig.map((route, index) => {
						const hasPermission = route.permissions.every(x =>
							permissions.includes(x),
						);

						if (hasPermission) {
							return (
								<Route
									key={index}
									path={route.path}
									element={route.element}
									exact={route.exact}
									index={route.index}
								/>
							);
						}
					})}
				</Routes>
			</main>
			<Toasts />
		</div>
	);
};

AdminLayout.propTypes = {
	online: bool,
};

export default AdminLayout;
