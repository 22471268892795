import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	faFaceFrown,
	faFaceMeh,
	faFaceSmile,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { wellBeingFormData } from 'core/helpers/well-being/well-being.form-data';
import {
	getGraphData,
	getGraphQuestionData,
} from 'core/helpers/well-being/well-being.helper';
import { useForm } from 'core/hooks/form/useForm';
import { useSubmit } from 'core/hooks/submit/useSubmit';
import {
	postWellBeing,
	putBarometer,
} from 'core/store/well-being/well-being.actions';

import Card from 'components/Card/Card';
import {
	Button,
	DatepickerInput,
	InputCheckbox,
	InputField,
} from 'components/Form';
import Graphic from 'components/Graphic/Graphic';
import { Col, Row } from 'components/Grid';
import { AdminHeader } from 'components/Layouts/AdminLayout/AdminHeader/AdminHeader';
import Content from 'components/Layouts/Content/Content';
import Sidebar from 'components/Layouts/Sidebar/Sidebar';

import AddWellBeingQuestion from './AddWellBeingQuestion';

import './WellBeingForm.scss';

const WellBeingForm = ({ object, registrations }) => {
	const navigate = useNavigate();

	const { handleSubmit } = useSubmit();

	const { permissions } = useSelector(state => state.authReducer);

	const [form] = useState(wellBeingFormData(object));
	const [graphData, setGraphData] = useState(getGraphData(registrations));

	useEffect(() => {
		if (Object.keys(registrations).length)
			setGraphData(getGraphData(registrations));
	}, [registrations]);

	const { register, reset, getValue, getValues, setValue, isValid } =
		useForm();

	const submitForm = async () => {
		await handleSubmit(postWellBeing(getValues(), object?.id, navigate));
	};

	const handleContacted = async id => {
		await handleSubmit(putBarometer(object?.id, id));
	};

	return (
		<Row className='entity'>
			<Col md={12}>
				<AdminHeader title='Barometer toevoegen' />

				<Card
					title='Barometer toevoegen'
					footer={
						Object.keys(registrations).length ? null : (
							<div className='btn__group'>
								<Button
									buttonStyle='primary'
									label='Bewaar'
									disabled={!isValid()}
									onClick={submitForm}
								/>
								<Button
									buttonStyle='secondary'
									label='Annuleer'
									onClick={() => {
										if (object) {
											navigate(`/welzijn`);
										} else {
											reset();
										}
									}}
								/>
							</div>
						)
					}>
					<Row>
						<Content hasSidebar>
							<AddWellBeingQuestion
								value={getValue('questions')}
								setValue={value => setValue('questions', value)}
								hasRegistrations={
									!!Object.keys(registrations).length
								}
							/>
						</Content>

						<Sidebar cartTitle='Algemeen'>
							<InputField {...register('name', form.name)} />

							<DatepickerInput
								{...register('start_date', form.start_date)}
							/>

							<DatepickerInput
								{...register('end_date', form.end_date)}
							/>

							<InputField
								{...register('questions', form.questions)}
							/>
						</Sidebar>
					</Row>
				</Card>

				{Object.keys(registrations).length ? (
					<Row>
						<Content hasSidebar>
							<h2 className='h1'>Registraties</h2>

							{permissions.includes('well_being_see_results') ? (
								Object.keys(registrations).length ? (
									<div className='well-being__registrations'>
										{Object.values(registrations).map(
											registration => (
												<Card
													title={`${registration.firstName} ${registration.lastName}`}
													className={
														registration.questions.some(
															x =>
																x.needAttention,
														) &&
														!registration.contacted
															? registration.questions.every(
																	x =>
																		x.needAttention,
																)
																? 'card__urgent'
																: 'card__attend'
															: 'card__done'
													}
													footer={
														<InputCheckbox
															id={`${registration.id}`}
															label='Deze persoon is gecontacteerd'
															name='contacted'
															onChange={() =>
																handleContacted(
																	registration.id,
																)
															}
															setTouched={() =>
																''
															}
															value={
																registration.contacted
															}
															style={{
																marginBottom:
																	'.6rem',
															}}
														/>
													}
													key={registration.id}
													withShadow
													withReadMore
													folded
													showArrow>
													{registration.questions.map(
														question => (
															<div
																className='question'
																key={
																	question.id
																}>
																<strong>
																	{
																		question.question
																	}
																</strong>
																<br />
																<div
																	className={`answer ${question.type}`}>
																	{question.type ===
																	'S' ? (
																		<FontAwesomeIcon
																			icon={
																				question.answer ===
																				'green'
																					? faFaceSmile
																					: question.answer ===
																						  'orange'
																						? faFaceMeh
																						: faFaceFrown
																			}
																			className={
																				question.answer
																			}
																			fixedWidth
																		/>
																	) : (
																		question.answer
																	)}
																</div>
															</div>
														),
													)}
												</Card>
											),
										)}
									</div>
								) : (
									<div className='empty-list'>
										Geen data om weer te geven
									</div>
								)
							) : null}
						</Content>

						<Sidebar>
							<Card title='Statistiek' withShadow>
								<Graphic
									type='bar'
									name='qty'
									title='Aantal ingevuld'
									data={[
										{
											base: object?.users,
											value: Object.keys(registrations)
												.length,
										},
									]}
									showLegend
								/>

								{object?.questions.map(question => {
									const data = getGraphQuestionData(
										question,
										registrations,
									);

									return data?.length ? (
										<Graphic
											type='bar'
											name='smily'
											title={question.question}
											data={data}
											showLegend
											key={`graph-${question.id}`}
										/>
									) : (
										''
									);
								})}

								<Graphic
									type='bar'
									name='support'
									title='Heeft ondersteuning nodig'
									data={graphData.needSupport}
									showLegend
									singleGraph
								/>

								<Graphic
									type='bar'
									name='contacted'
									title='Zijn gecontacteerd'
									data={graphData.gotSupport}
									showLegend
								/>
							</Card>
						</Sidebar>
					</Row>
				) : null}
			</Col>
		</Row>
	);
};

export default WellBeingForm;
