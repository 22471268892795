import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';

export const loadOrganisers = () => () => {
	dispatchAction({
		type: ACTION_TYPES.ORGANISERS_LOAD,
		payload: { organisersLoading: true },
		meta: {
			action: {
				effect: {
					url: '/admin/organisers',
					method: 'get',
				},
				commit: { type: ACTION_TYPES.ORGANISERS_LOAD_COMMIT },
			},
			followup: [],
		},
	});
};
