import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

let defaultState = {
	movingMissions: [],
	mission: null,
	registrations: {},
	movingMissionLoading: false,

	detail: null,
	detailLoading: false,
};

const loadMissions = (state, { movingMissionLoading }) => ({
	...state,
	movingMissionLoading,
	movingMission: null,
	registrations: {},
});

const loadMissionsCommit = (state, { data }) => ({
	...state,
	movingMissions: data,
	movingMissionLoading: false,
});

const loadMission = (state, { movingMissionLoading, movingMission }) => ({
	...state,
	movingMissionLoading,
	movingMission,
});

const loadMissionCommit = (state, payload) => ({
	...state,
	movingMission: payload,
	movingMissionLoading: false,
});

const deleteMission = (state, { id }) => ({
	...state,
	movingMissions: state.movingMissions.filter(item => item.id !== id),
});

const loadRegistrations = (state, { movingMissionLoading }) => ({
	...state,
	movingMissionLoading,
});

const loadRegistrationsCommit = (state, payload) => ({
	...state,
	registrations: payload,
	movingMissionLoading: false,
});

const loadDetail = (state, { detailLoading }) => ({
	...state,
	detailLoading,
});

const loadDetailCommit = (state, payload) => ({
	...state,
	detail: payload,
	detailLoading: false,
});

export const movingMissionReducer = (
	state = defaultState,
	{ type, payload },
) => {
	switch (type) {
		case ACTION_TYPES.MOVING_MISSIONS_LOAD:
			return loadMissions(state, payload);
		case ACTION_TYPES.MOVING_MISSIONS_LOAD_COMMIT:
			return loadMissionsCommit(state, payload);
		case ACTION_TYPES.MOVING_MISSION_LOAD:
			return loadMission(state, payload);
		case ACTION_TYPES.MOVING_MISSION_LOAD_COMMIT:
			return loadMissionCommit(state, payload.data);
		case ACTION_TYPES.MOVING_MISSION_DELETE_COMMIT:
			return deleteMission(state, payload);
		case ACTION_TYPES.MOVING_MISSION_LOAD_REGISTRATIONS:
			return loadRegistrations(state, payload);
		case ACTION_TYPES.MOVING_MISSION_LOAD_REGISTRATIONS_COMMIT:
			return loadRegistrationsCommit(state, payload.data);
		case ACTION_TYPES.MOVING_MISSION_LOAD_DETAIL:
			return loadDetail(state, payload);
		case ACTION_TYPES.MOVING_MISSION_LOAD_DETAIL_COMMIT:
			return loadDetailCommit(state, payload.data);
		default:
			return state;
	}
};
