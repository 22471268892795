import { Link } from 'react-router-dom';
import { INPUT_TYPES } from 'core/constants';
import { useForm } from 'core/hooks/form/useForm';
import { useOnlineStatus } from 'core/hooks/OnlineStatusProvider';
import { array, bool, func, object, string } from 'prop-types';

import Card from 'components/Card/Card';
import { Row } from 'components/Grid';
import Modal from 'components/Modal/Modal';

import {
	Button,
	Datepicker,
	DatepickerInput,
	FormatCol,
	FormatGroup,
	InputCheckbox,
	InputField,
	InputRadioButton,
	Select,
	Textarea,
	Toggle,
} from '../index';

import './Form.scss';

const Form = ({
	fields,
	layout,
	showDefaultButtons,
	submitLabel,
	submitOnClick,
	addCancel,
	cancelOnClick,
	buttons,
	disableWhenOffline,
	className,
	isModal,
	showModal,
	setShowModal,
	modalSize,
	isCard,
	cardTitle,
}) => {
	const online = useOnlineStatus();

	const { register, getValues, isValid, reset } = useForm();

	const loadElement = (field, index) => {
		switch (field.type) {
			case 'row':
				return (
					<Row key={`row-${index}`}>
						{field.fields.map((x, i) => {
							const v = fields.find(f => f.name === x);
							return loadElement(v, i);
						})}
					</Row>
				);

			case 'col':
				return (
					<FormatCol
						name={field.name}
						breakpoint={field?.breakpoint}
						order={field?.order}
						key={`col-${index}`}>
						{field.fields.map((x, i) => {
							const v = fields.find(f => f.name === x);
							return loadElement(v, i);
						})}
					</FormatCol>
				);

			case 'group':
				return (
					<FormatGroup
						name={field.name}
						label={field.label}
						direction={field.direction}
						key={`group-${index}`}>
						{field.fields.map((x, i) => {
							const v = fields.find(f => f.name === x);
							return loadElement(v, i);
						})}
					</FormatGroup>
				);

			case INPUT_TYPES.CHECKBOX:
				return (
					<InputCheckbox
						{...register(field.name, {
							...field,
							disabled:
								field.disabled ||
								(!online && disableWhenOffline),
						})}
					/>
				);

			case INPUT_TYPES.RADIO:
				return (
					<InputRadioButton
						{...register(field.name, {
							...field,
							disabled:
								field.disabled ||
								(!online && disableWhenOffline),
						})}
					/>
				);

			case INPUT_TYPES.TEXTAREA:
				return (
					<Textarea
						{...register(field.name, {
							...field,
							disabled:
								field.disabled ||
								(!online && disableWhenOffline),
						})}
					/>
				);

			case INPUT_TYPES.SELECT:
			case INPUT_TYPES.CREATABLE:
				return (
					<Select
						{...register(field.name, {
							...field,
							disabled:
								field.disabled ||
								(!online && disableWhenOffline),
						})}
					/>
				);

			/*case INPUT_TYPES.TIMERANGE:
				return <RangePicker {...props} />;*/

			case INPUT_TYPES.DATEPICKER:
				return (
					<Datepicker
						{...register(field.name, {
							...field,
							disabled:
								field.disabled ||
								(!online && disableWhenOffline),
						})}
					/>
				);

			case INPUT_TYPES.DATEINPUT:
				return (
					<DatepickerInput
						{...register(field.name, {
							...field,
							disabled:
								field.disabled ||
								(!online && disableWhenOffline),
						})}
					/>
				);

			case INPUT_TYPES.TOGGLE:
				return (
					<Toggle
						{...register(field.name, {
							...field,
							disabled:
								field.disabled ||
								(!online && disableWhenOffline),
						})}
					/>
				);

			/*case INPUT_TYPES.FILE:
				return <Upload {...props} />;*/

			default:
				return (
					<InputField
						{...register(field.name, {
							...field,
							disabled:
								field.disabled ||
								(!online && disableWhenOffline),
						})}
					/>
				);
		}
	};

	const loadButton = (button, i) => {
		const { buttonType, buttonStyle, action, style, label, disabled } =
			button;

		return (
			<Button
				onClick={action}
				type={buttonType ?? 'button'}
				buttonStyle={buttonStyle ?? 'primary'}
				label={label}
				style={style}
				disabled={disabled ?? false}
				key={i}
			/>
		);
	};

	const loadLink = (link, i) => {
		const { action, style, label } = link;

		return (
			<Link to={action} className='btn btn--link' style={style} key={i}>
				{label}
			</Link>
		);
	};

	const cancelForm = () => {
		if (cancelOnClick) {
			cancelOnClick();
		}

		reset();
		if (isModal) setShowModal(false);
	};

	const submitForm = () => {
		submitOnClick(getValues());
		reset();

		if (isModal) setShowModal(false);
	};

	const render = () => {
		return layout
			? loadElement(layout)
			: fields.map(field => loadElement(field));
	};

	const renderButtons = () => {
		return showDefaultButtons || addCancel || buttons.length ? (
			<div className='btn__group'>
				{showDefaultButtons ? (
					<Button
						onClick={submitForm}
						buttonStyle='primary'
						label={submitLabel}
						disabled={!isValid()}
					/>
				) : null}

				{addCancel ? (
					<Button
						onClick={cancelForm}
						buttonStyle='secondary'
						label='Annuleren'
					/>
				) : null}

				{buttons?.map((button, i) => {
					if (button.type === 'link') {
						return loadLink(button, i);
					} else {
						return loadButton(button, i);
					}
				})}
			</div>
		) : null;
	};

	return isModal ? (
		<Modal
			show={showModal}
			close={cancelForm}
			title='Geef de waarden in'
			cancel={addCancel}
			actions={[
				...buttons,
				...(showDefaultButtons
					? [
							{
								action: submitForm,
								label: submitLabel,
								disabled: !isValid(),
							},
						]
					: []),
			]}
			large={modalSize === 'large'}
			medium={modalSize === 'medium'}
			small={modalSize === 'small'}>
			{render()}
		</Modal>
	) : isCard ? (
		<Card title={cardTitle} footer={renderButtons()}>
			{render()}
		</Card>
	) : (
		<div className={className}>
			{render()}
			{renderButtons()}
		</div>
	);
};

Form.defaultProps = {
	layout: null,
	showDefaultButtons: true,
	submitLabel: 'Bewaren',
	addCancel: false,
	buttons: [],
	disableWhenOffline: false,
	className: '',
	isModal: false,
	isCard: false,
};

Form.propTypes = {
	fields: array.isRequired,
	layout: object,
	showDefaultButtons: bool,
	submitLabel: string,
	submitOnClick: func.isRequired,
	addCancel: bool,
	cancelOnClick: func,
	buttons: array,
	disableWhenOffline: bool,
	className: string,

	isModal: bool,
	showModal: (props, propName, componentName) => {
		if (
			props.isModal &&
			(props[propName] === undefined ||
				typeof props[propName] !== 'boolean')
		) {
			return new Error(
				`Missing prop ${propName} supplied to ${componentName}. Validation failed.`,
			);
		}
	},
	setShowModal: (props, propName, componentName) => {
		if (
			props.isModal &&
			(!props[propName] || typeof props[propName] !== 'function')
		) {
			return new Error(
				`Missing prop ${propName} supplied to ${componentName}. Validation failed.`,
			);
		}
	},
	modalSize: string,

	idCard: bool,
	cardTitle: (props, propName, componentName) => {
		if (
			props.isCard &&
			(!props[propName] || typeof props[propName] !== 'string')
		) {
			return new Error(
				`Missing prop ${propName} supplied to ${componentName}. Validation failed.`,
			);
		}
	},
};

export default Form;
